<template>
  <div ref="modal" class="modal fade" :id="modalId" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" :data-bs-backdrop="backdrop">
    <div :class="['modal-dialog', size && `modal-${size}`, {'modal-dialog-centered' : centered}, {'modal-dialog-scrollable' : scrollable}]">
      <div class="modal-content">
        <div v-if="showHeader" :class="['modal-header', `justify-content-${title || titleClass ? 'between' : 'end'}` ,headerClass]">
          <h5 class="modal-title" id="exampleModalLabel">
            <slot name="header">{{ title }}</slot>
          </h5>
          <button type="button" class="btn-close-x" data-bs-dismiss="modal" aria-label="Close">×</button>
        </div>
        
        <div class="modal-body">
          <slot></slot>
        </div>
        
        <div v-if="showFooter" :class="['modal-footer', footerClass]">
          <slot name="footer">
            <base-button type="danger" size="sm" textColor="white" data-bs-dismiss="modal">Close</base-button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import { Modal } from 'bootstrap';

export default defineComponent({
  name: 'base-modal',
  props: {
    modalId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    titleClass: {
      type: Boolean,
      default: true,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String
    },
    centered: {
      type: Boolean,
      default: false
    },
    scrollable: {
      type: Boolean,
      default: false
    },
    headerClass: String,
    footerClass: String,
    backdrop: {
      type: Boolean || String,
      default: true
    }
  },
  setup(_, { emit }) {

    const modal = ref()
    const myModal = ref()

    onMounted(() => {
      if (modal.value) {
        myModal.value = new Modal(modal.value);
      }
    })

    function show() {
      document.body.appendChild(modal.value!);
      myModal.value.show()
      emit('show')
    }

    function hide() {
      myModal.value.hide()
    }

    return {
      show,
      hide,
      modal,
      myModal
    }
  }
})

</script>

<style scoped lang="scss">
.btn-close-x {
    color: rgba(0,0,0,.6);
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1;
    text-shadow: none;
    opacity: .5;
    border: none;
    text-decoration: none;
    background: none;
    padding: 0.5rem;
    margin-right: 4px;
}

.btn-close-x:hover{
    opacity: .75;
}

</style>

