import { FetchBase, fetchable } from '@/modules/common/domain'
import UserProfileService from '@/modules/common/services/UserProfileService';
import { IUserBrief, IFetchable } from '@/modules/common/domain/types';

export class UserBrief extends FetchBase implements IFetchable<IUserBrief>, IUserBrief {
    public firstName = '';
    public lastName = '';
    public userId = '';

    private userProfileService = new UserProfileService();

    @fetchable
    public async fetch(organisationId: string, userId: string) {
        const user = await this.userProfileService.getUserBrief(organisationId, userId);

        this.firstName = user.firstName;
        this.lastName = user.lastName;
        this.userId = user.userId;
    }
}
