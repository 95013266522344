import { renderSlot as _renderSlot, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({'active': _ctx.isActive}),
    id: _ctx.tabId
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 10, _hoisted_1)), [
    [_vShow, _ctx.isActive]
  ])
}