import store from '@/store';
import { RouteRecordRaw, NavigationGuard } from 'vue-router';
import { IModule } from '@/framework/domain/types/IModule';
import AppFooter from "@/framework/components/layout/AppFooter.vue";

export class BankingProductsModule implements IModule {

    public name = 'bankingProducts';
    public title = 'FINANCIAL PRODUCTS';
    public description = 'Manage financial product information and target markets';
    public landingRoute = '/landing';

    public isCurrent(route: string): boolean {
        if (/^\/landing/.test(route)) return true;
        if (/^\/org/.test(route)) return true;
        if (/^\/usage/.test(route)) return true;
        if (/^\/insight/.test(route)) return true;
        if (/^\/settings/.test(route)) return true;
        return false;
    }

    public isModuleEnabledForUser(): boolean {
        if (!store.state.currentUser?.customerModules) return false;

        if (store.state.currentUser.customerModules.bankingProducts === 'ALL_USERS') return true

        if (store.state.currentUser.customerModules.bankingProducts === 'SELECTED_USERS') {
            if (store.state.currentUser.organisationRoles) {
                const rolesObject = store.state.currentUser.organisationRoles;
                for (const org in rolesObject) {
                    if (rolesObject.hasOwnProperty(org)) {
                        const roles = rolesObject[org].roles;

                        const hasTrueRole = Object.values(roles).some(value => value === true);
                        if (hasTrueRole) {
                            return true;
                        }
                    }
                }
            }
        }
        return false
    }

    public routes(guardSecure: NavigationGuard, guardPublic: NavigationGuard): RouteRecordRaw[] {
        return [
            {
                path: "/landing",
                beforeEnter: guardSecure,
                name: "Landing",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/Landing.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/org/:orgId/products/create",
                beforeEnter: guardSecure,
                name: "ProductsCreate",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/ProductCreate.vue"),
                },
            },
            {
                path: "/org/:orgId/products/view",
                beforeEnter: guardSecure,
                name: "product-portfolio-page",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/ProductPortfolio.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/org/:orgId/products/rates",
                beforeEnter: guardSecure,
                name: "products-rates-page",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/ProductsRates.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/org/:orgId/portfolioValidation/:viewType?",
                beforeEnter: guardSecure,
                name: "product-validation",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/ProductValidation.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/org/:orgId/products/view/decommissioned",
                beforeEnter: guardSecure,
                name: "ProductDecommission",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/ProductDecommission.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/org/:orgId/products/view/rates",
                beforeEnter: guardSecure,
                name: "ProductRates",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/ProductRates.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/org/:orgId/products/view/:productId",
                beforeEnter: guardSecure,
                name: "ProductView",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/ProductView.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/org/:orgId/products/view/:productId/:viewType",
                beforeEnter: guardSecure,
                name: "ProductViewDirect",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/ProductView.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/org/:orgId/products/edit/:productId",
                beforeEnter: guardSecure,
                name: "ProductEdit",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/ProductEdit.vue"),
                },
            },
            {
                path: "/org/:orgId/products/versions/:productId/:eventId1/compare/:eventId2",
                beforeEnter: guardSecure,
                name: "VersionCompare",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/VersionCompare.vue"),
                },
            },
            {
                path: "/org/:orgId/workflows/create/:productId",
                beforeEnter: guardSecure,
                name: "CreateWorkflow",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/CreateWorkflow.vue"),
                },
            },
            {
                path: "/org/:orgId/workflows/view/:workflowId",
                beforeEnter: guardSecure,
                name: "Workflow",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/Workflow.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/org/:orgId/reviews",
                beforeEnter: guardSecure,
                name: "Reviews",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/Reviews.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/usage/external",
                beforeEnter: guardSecure,
                name: "UsageExternal",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/Construction.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/usage/internal",
                beforeEnter: guardSecure,
                name: "UsageInternal",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/Construction.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/insight/org/:orgId/products/view/:productId",
                beforeEnter: guardSecure,
                name: "InsightView",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/MarketProductView.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/insight/org/:orgId/products/view/:productId/:viewType",
                beforeEnter: guardSecure,
                name: "InsightViewDirect",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/MarketProductView.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/insight/org/:orgId/products/versions/:productId/:auditId1/compare/:auditId2",
                beforeEnter: guardSecure,
                name: "MarketVersionCompare",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () =>
                        import("./views/secure/MarketVersionCompare.vue"),
                },
            },
            {
                path: "/insight/portfolio",
                beforeEnter: guardSecure,
                name: "market-portfolio-page",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/MarketPortfolios.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/insight/compare",
                beforeEnter: guardSecure,
                name: "InsightCompare",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/MarketComparison.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/insight/trend",
                beforeEnter: guardSecure,
                name: "InsightTrend",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/MarketTrends.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/insight/changes/:date?",
                beforeEnter: guardSecure,
                name: "InsightChanges",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/MarketChanges.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/insight/notifications",
                beforeEnter: guardSecure,
                name: "InsightNotifications",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/MarketNotifications.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/insight/report/:viewType?",
                beforeEnter: guardSecure,
                name: "MarketReports",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/MarketReports.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/settings/api/external/:orgId",
                beforeEnter: guardSecure,
                name: "EditExternalAPI",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/ThirdPartyConfig.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/settings/api/external/:orgId/:viewType",
                beforeEnter: guardSecure,
                name: "EditExternalAPIDirect",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/ThirdPartyConfig.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/settings/api/internal",
                beforeEnter: guardSecure,
                name: "EditInternalAPI",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/Construction.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/settings/api/:orgId/metrics/:tabName",
                beforeEnter: guardSecure,
                name: "UsageAnalytics",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/UsageAnalytics.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/org/:orgId/settings/general/:tabName",
                beforeEnter: guardSecure,
                name: "EditOrganisationSettings",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/OrgSettings.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/org/:orgId/settings/products/:tabName",
                beforeEnter: guardSecure,
                name: "EditProductSchema",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/ProductSettings.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/org/:orgId/settings/tmds/:tabName",
                beforeEnter: guardSecure,
                name: "EditTmdSchema",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/TmdSettings.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/settings/:orgId/activity",
                beforeEnter: guardSecure,
                name: "UserActivity",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/UserActivity.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/settings/:orgId/activity/:tabName",
                beforeEnter: guardSecure,
                name: "UserActivityTab",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/UserActivity.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/org/:orgId/tmds/create",
                beforeEnter: guardSecure,
                name: "TmdsCreate",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/TmdCreate.vue"),
                },
            },
            {
                path: "/org/:orgId/events/future",
                beforeEnter: guardSecure,
                name: "FutureEvents",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/FutureEvents.vue"),
                },
            },
            {
                path: "/org/:orgId/tmds/view",
                beforeEnter: guardSecure,
                name: "tmds-view-page",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/TmdPortfolio.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/org/:orgId/tmds/view/decommissioned",
                beforeEnter: guardSecure,
                name: "TmdDecommission",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/TmdDecommission.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/org/:orgId/tmds/view/:tmdId",
                beforeEnter: guardSecure,
                name: "TmdView",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/TmdView.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/org/:orgId/tmds/edit/:tmdId",
                beforeEnter: guardSecure,
                name: "TmdEdit",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/TmdEdit.vue"),
                },
            },
            {
                path: "/org/:orgId/tmds/view/:tmdId/:viewType",
                beforeEnter: guardSecure,
                name: "TmdViewDirect",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/TmdView.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/org/:orgId/tmds/workflows/view/:workflowId",
                beforeEnter: guardSecure,
                name: "TmdWorkflow",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/TmdWorkflow.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/org/:orgId/tmds/workflows/:tmdId",
                beforeEnter: guardSecure,
                name: "TmdCreateWorkflow",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/TmdCreateWorkflow.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/org/:orgId/tmds/versions/:tmdId/:eventId1/compare/:eventId2",
                beforeEnter: guardSecure,
                name: "TmdVersionCompare",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/TmdVersionCompare.vue"),
                },
            },
            {
                path: "/org/:orgId/report",
                beforeEnter: guardSecure,
                name: "OrgStandardReports",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/OrganisationReports.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/org/:orgId/report/:viewType",
                beforeEnter: guardSecure,
                name: "OrgStandardReportsView",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/OrganisationReports.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/org/:orgId/governance/workflows/create",
                beforeEnter: guardSecure,
                name: "CreateGovernanceWorkflow",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () =>
                        import("./views/secure/CreateGovernanceWorkflow.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/org/:orgId/governance/workflows/view/:workflowId",
                beforeEnter: guardSecure,
                name: "GovernanceWorkflow",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/GovernanceWorkflow.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/org/:orgId/governance/workflows",
                beforeEnter: guardSecure,
                name: "GovernanceWorkflowPortfolio",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () =>
                        import("./views/secure/GovernanceWorkflowPortfolio.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/org/:orgId/fees/view",
                beforeEnter: guardSecure,
                name: "fees-library-page",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/FeeLibrary.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/org/:orgId/fees/create",
                beforeEnter: guardSecure,
                name: "fee-create-page",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/FeeCreate.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/org/:orgId/fees/view/:feeId",
                beforeEnter: guardSecure,
                name: "fee-view-page",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/FeeView.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/org/:orgId/fees/view/:feeId/:viewType",
                beforeEnter: guardSecure,
                name: "FeeViewDirect",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/FeeView.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/org/:orgId/fees/edit/:feeId",
                beforeEnter: guardSecure,
                name: "fee-edit-page",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/FeeEdit.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/org/:orgId/organisationWorkflows/:viewType?",
                beforeEnter: guardSecure,
                name: "ViewOrganisationWorkflows",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/OrganisationWorkflow.vue"),
                },
            },
            {
                path: "/org/:orgId/organisationWorkflows/closedProductWorkflows",
                beforeEnter: guardSecure,
                name: "ClosedProductWorkflows",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/ClosedProductWorkflows.vue"),
                },
            },
            {
                path: "/org/:orgId/organisationWorkflows/closedTmdWorkflows",
                beforeEnter: guardSecure,
                name: "ClosedTmdWorkflows",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/ClosedTmdWorkflows.vue"),
                },
            },
            {
                path: "/org/:orgId/groupWorkflows/create/:type",
                beforeEnter: guardSecure,
                name: "CreateGroupWorkflowPage",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/CreateGroupWorkflowPage.vue"),
                    footer: AppFooter,
                },
            },
        ]
    }
}


