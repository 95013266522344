import store from '@/store'
import { UserProfile } from '@/modules/common/domain'

export class AuthAssert {
    public get read(): boolean {
        try {
            if (!this.orgSelected) return false;
            if (this.support) return true;
            return (store.state.currentUser as UserProfile).organisationRoles[store.state.organisation.organisationId].roles.read === true;
        } catch (_) { }
        return false;
    }

    public get admin(): boolean {
        try {
            if (!this.orgSelected) return false;
            if (this.support) return true;
            return (store.state.currentUser as UserProfile).organisationRoles[store.state.organisation.organisationId].roles.admin === true;
        } catch (_) { }
        return false;
    }

    public get edit(): boolean {
        try {
            if (!this.orgSelected) return false;
            if (this.support) return true;
            return (store.state.currentUser as UserProfile).organisationRoles[store.state.organisation.organisationId].roles.edit === true;
        } catch (_) { }
        return false;
    }

    public get approve(): boolean {
        try {
            if (!this.orgSelected) return false;
            if (this.support) return true;
            return (store.state.currentUser as UserProfile).organisationRoles[store.state.organisation.organisationId].roles.approve === true;
        } catch (_) { }
        return false;
    }

    public get support(): boolean {
        try {
            if (!this.orgSelected) return false;
            return (store.state.currentUser as UserProfile).organisationRoles[store.state.organisation.organisationId].roles.support === true;
        } catch (_) { }
        return false;
    }

    public get orgSelected(): boolean {
        try {
            if (!this.login) return false;
            return !(store.state.organisation.organisationId == null) && (store.state.organisation.organisationId !== '');
        } catch (_) { }
        return false;
    }

    public get login(): boolean {
        try {
            return store.state.$auth.isAuthenticated;
        } catch (_) { }
        return false;
    }
}
