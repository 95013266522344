<template>
    <div class="menu">
        <VueDatePicker
            v-model="inputValue"
            :placeholder="placeholder"
            :auto-position="autoPosition"
            :auto-apply="autoApply"
            :enable-time-picker="false"
            :calendar-cell-class-name="calendarCellClassName"
            :input-class-name="inputClassName"
            :menu-class-name="menuClassName"
            :time-picker="timePicker"
            :clearable="false"
            :max-date="max"
            :min-date="min"
            :state="state"
            :format="format"
            @closed="hidden"
            :disabled="disabled"
            :locale="locale"
        ></VueDatePicker>
    </div>
</template>
<script lang="ts">
import { defineComponent, computed, ref } from "vue";

export default defineComponent({
    name: "form-date-input",
    props: {
        placeholder: {
            type: String,
            default: "No date selected",
        },
        modelValue: {
            default: "",
        },
        autoPosition: {
            type: Boolean,
            default: false,
        },
        max: {
            type: String || Date
        },
        min: {
            type: String || Date
        },
        autoApply: {
            type: Boolean,
            default: true,
        },
        calendarCellClassName: {
            type: String,
            default: "dp-custom-cell",
        },
        inputClassName: {
            default: '',
            type: String
        },
        menuClassName: {
            type: String,
            default: ''
        },
        timePicker: {
            default: false,
            type: Boolean
        },
        state: {
            type: Boolean || null,
            default: null
        },
        modelType: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        locale: String
    },
    setup(props, { emit }) {
        const timePicker = ref(props.timePicker);
        const dateFormat = ref(props.modelType);

        const inputValue = computed({
            get() {
                if(props.timePicker && props.modelValue) {
                    let time = props.modelValue.split(':')
                    return {
                        hours: time[0],
                        minutes: time[1]
                    }
                }
                return props.modelValue;
            },
            set(value: any) {
                if (props.timePicker) {
                    value = timeFormatter(value)
                }
                emit("update:modelValue", value);
                emit("input", value)
            },
        });

        function format(dateString: any) {
            if (!timePicker.value) {
                if (dateFormat.value == "dd-month-yyyy") {
                    const date = new Date(dateString);
                    const day = date.getDate();
                    const month = date.toLocaleString('en-US', { month: 'long' });
                    const year = date.getFullYear();

                    return `${day} ${month} ${year}`;
                } else {
                    const date = new Date(dateString);
                    const Week = date.toLocaleString('en-US', { weekday: 'long' });
                    const day = date.getDate();
                    const month = date.toLocaleString('en-US', { month: 'long' });
                    const year = date.getFullYear();

                    return `${Week}, ${day} ${month} ${year}`;
                }
            }
        }

        function timeFormatter(value: any) {
            let time = value
            if(String(time.hours).length == 1) {
                typeof(time.hours)
                time.hours = `0${time.hours}`
            }
            if(String(time['minutes']).length == 1) {
                time['minutes'] = `0${time['minutes']}`
            }
            if(String(time['seconds']).length == 1) {
                time['seconds'] = `0${time['seconds']}`
            }
            return  `${time.hours}:${time.minutes}:${time.seconds}`
        }

        function hidden () {
            emit('hidden')
        }



        return {
            inputValue,
            hidden,
            format
        };
    },
});
</script>
<style scoped lang="scss">
@import "~bootstrap/scss/functions";
@import "@/assets/scss/custom/_functions";
@import "@/assets/scss/custom/_variables";
@import "~bootstrap/scss/variables";

.menu :deep(.dp__theme_light) {
    --dp-text-color: #8898aa;
    --dp-font-size: 0.8rem;
    min-height: calc(2px + 1.8125rem);
    --dp-border-color-hover: #cad1d7;
    --dp-border-color: #cad1d7;
    --dp-primary-color: #3652a3;
    --dp-input-padding: 5px 30px 5px 12px;
}
.dp__theme_light :deep(.dp-custom-cell) {
    border-radius: 50%;
    font-weight: 600;
}
</style>
