import store from '@/store';
import { RouteRecordRaw, NavigationGuard } from 'vue-router';
import { IModule } from '@/framework/domain/types/IModule';
import AppFooter from "@/framework/components/layout/AppFooter.vue";

export class EnergyProductsModule implements IModule {

    public name = 'energyProducts';
    public title = 'ENERGY PRODUCTS';
    public description = 'Manage energy plan refernce data and pricing';
    public landingRoute = '/energy/landing';

    public isCurrent(route: string): boolean {
        if (/^\/energy/.test(route)) return true;
        return false;
    }

    public isModuleEnabledForUser(): boolean {
        if (!store.state.currentUser?.customerModules) return false;

        if (store.state.currentUser.customerModules.energyProducts === 'ALL_USERS') return true;

        if (store.state.currentUser.customerModules.energyProducts === 'SELECTED_USERS') {
            if (store.state.currentUser.modules) {
                if (store.state.currentUser.modules.energyProducts.org.roles) {
                    const roles = store.state.currentUser.modules.energyProducts.org.roles;
                    const roleEnable = Object.values(roles).some(value => value === true);
                    if (roleEnable) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    public routes(guardSecure: NavigationGuard, guardPublic: NavigationGuard): RouteRecordRaw[] {
        return [
            {
                path: "/energy/landing",
                beforeEnter: guardSecure,
                name: "EnergyLanding",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/Landing.vue"),
                    footer: AppFooter,
                },
            },    
            {
                path: "/energy/landing",
                beforeEnter: guardSecure,
                name: "EnergyLanding",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/Landing.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/energy/insight/org/:orgId/plans/view/:planId",
                beforeEnter: guardSecure,
                name: "EnergyInsightView",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/MarketPlanView.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/energy/insight/org/:orgId/plans/view/:planId/:viewType",
                beforeEnter: guardSecure,
                name: "EnergyInsightViewDirect",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/MarketPlanView.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/energy/insight/org/:orgId/plans/versions/:planId/:auditId1/compare/:auditId2",
                beforeEnter: guardSecure,
                name: "EnergyMarketVersionCompare",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () =>
                        import("./views/secure/MarketVersionCompare.vue"),
                },
            },
            {
                path: "/energy/insight/portfolio",
                beforeEnter: guardSecure,
                name: "energy-market-portfolio-page",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/MarketPortfolios.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/energy/insight/compare",
                beforeEnter: guardSecure,
                name: "EnergyInsightCompare",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/MarketComparison.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/energy/insight/trend",
                beforeEnter: guardSecure,
                name: "EnergyInsightTrend",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/MarketTrends.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/energy/insight/changes/:date?",
                beforeEnter: guardSecure,
                name: "EnergyInsightChanges",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/MarketChanges.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/energy/insight/notifications",
                beforeEnter: guardSecure,
                name: "EnergyInsightNotifications",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/MarketNotifications.vue"),
                    footer: AppFooter,
                },
            },
            {
                path: "/energy/insight/report/:viewType?",
                beforeEnter: guardSecure,
                name: "EnergyMarketReports",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/MarketReports.vue"),
                    footer: AppFooter,
                },
            },
        ]
    }
}


