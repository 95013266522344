export default {
    beforeMount: function(el: any, binding: any, vnode: any): any {
        el.clickOutsideEvent = function(event: any): any {
            // here I check that click was outside the el and his childrens
            if (!(el === event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                binding.value(event);
            }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unmounted: function(el: any): any {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    }
}
