import { FetchBase, fetchable } from '@/modules/common/domain'
import { ITargetMarketHistory, ITmdEvent, ITargetMarketHistoryComments } from '@/modules/products/domain/types';
import TargetMarketHistoryService from '@/modules/products/services/TmdHistoryService';
import { IFetchable } from '@/modules/common/domain/types/IFetchable';

export class TargetMarketHistory extends FetchBase implements IFetchable<ITargetMarketHistory>, ITargetMarketHistory { 
    private targetMarketService = new TargetMarketHistoryService();
    public tmdEvents: ITmdEvent[] = [];

    @fetchable
    public async fetch(orgId: string, marketId: string) {
        this.tmdEvents = await this.targetMarketService.getTargetMarketHistory(orgId, marketId);
    }
}
