import { FetchBase, fetchable } from '@/modules/common/domain'
import * as Generated from '@/modules/products/domain/generated'
import FeeService from '@/modules/products/services/FeeService';
import { IFetchable} from '@/modules/common/domain/types/IFetchable';
import { IFee } from './types';

export class Fee extends FetchBase implements IFetchable<IFee>, IFee {
    public data: Generated.Fees = {};
    public feeId: string = "";
    public isActive?: boolean;
    public organisationId: string = "";
    public createdAt: string = "";
    public name: string = "";
    public labels?: any[] | undefined;

    private feeService = new FeeService();

    @fetchable
    public async fetch(orgID: string, feeId: string) {
        let data = await this.feeService.getFee(orgID , feeId);
        // console.log({data})
        this.data = data.data;
        this.feeId = data.feeId;
        this.isActive = data.isActive;
        this.organisationId = data.organisationId;
        this.createdAt = data.createdAt;
        this.name = data.name
        this.labels = data.labels;
    }
}
