import { FetchBase, fetchable } from '@/modules/common/domain'
import TMDWorkflowService from '@/modules/products/services/TmdWorkflowService';
import { ITargetMarketWorkflows, ITargetMarketWorkflow } from '@/modules/products/domain/types';
import { IFetchable } from '@/modules/common/domain/types/IFetchable';

export class WorkflowForTargetMarket extends FetchBase implements IFetchable<ITargetMarketWorkflows>, ITargetMarketWorkflows {
    public tmdWorkflows: ITargetMarketWorkflow[] = [];

    private tmdWorkflowService = new TMDWorkflowService();

    @fetchable
    public async fetch(orgID: string) {
        let workflows: ITargetMarketWorkflow[] = [];
        workflows = await this.tmdWorkflowService.getAllTmdWorkflows(orgID)
        this.tmdWorkflows = workflows;
    }
}
