import { FetchBase, fetchable } from '@/modules/common/domain'
import IndustryService from '@/modules/products/services/IndustryService';
import { IMarketChanges, IMarketChange } from '@/modules/products/domain/types';
import { IFetchable } from '@/modules/common/domain/types/IFetchable';

export class MarketChanges extends FetchBase implements IFetchable<IMarketChanges>, IMarketChanges {
    public date = '';
    public changes: IMarketChange[] = [];

    private industryService = new IndustryService();

    @fetchable
    public async fetch(date: string) {
        this.date = date;
        this.changes = await this.industryService.getMarketChanges(date);
    }
}
