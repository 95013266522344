import { FetchBase, fetchable } from '@/modules/common/domain'
import IndustryService from '@/modules/products/services/IndustryService';
import { IMarketHolder, IMarketHolders } from '@/modules/products/domain/types';
import { IFetchable } from '@/modules/common/domain/types/IFetchable';

export class MarketHolders extends FetchBase implements IFetchable<IMarketHolders>, IMarketHolders {
    public holders: IMarketHolder[] = []

    private industryService = new IndustryService();

    @fetchable
    public async fetch() {
        const allHolders = await this.industryService.getMarketHolders();
        // Filtering out market holders who are 'disabled'
        this.holders = allHolders.filter(holder => !holder.disabled);
    }
}
