import { FetchBase, fetchable } from './FetchBase'
import { IFetchable } from './types/IFetchable'
import { IUserProfile } from './types/IUserProfile'
import UserProfileService from '@/modules/common/services/UserProfileService'

export class UserProfile extends FetchBase implements IFetchable<IUserProfile>, IUserProfile {
  public firstName = ''
  public lastName = ''
  public email = ''
  public customerId? = ''
  public customerModules?:{[key: string]: string}={}
  public organisationIds: string[] = []
  public lastWhatsNewId?: string | undefined
  public organisationRoles: {
      [key: string]: {
          roles: {
              read?: boolean
              edit?: boolean
              approve?: boolean
              admin?: boolean
              support?: boolean
          }
      }
  } = {}
  public  modules?: {    
    [key: string]: {
        org:{
            roles:{
            read?: boolean;
            edit?: boolean;
            approve?: boolean;
            admin?: boolean;
            support?: boolean;
            }
        }
    }
} = {}
  public mfaEnabled?: boolean
  public sso?: boolean

  private userProfileService = new UserProfileService();

  @fetchable
  public async fetch(options?: any) {
    const userProfile = await this.userProfileService.getUserProfile();
    this.firstName = userProfile.firstName
    this.lastName = userProfile.lastName
    this.email = userProfile.email
    this.organisationRoles = userProfile.organisations
    this.modules = userProfile.modules
    this.organisationIds = Object.keys(userProfile.organisations)
    this.lastWhatsNewId = userProfile.lastWhatsNewId ? userProfile.lastWhatsNewId : undefined
    this.mfaEnabled = userProfile.mfaEnabled
    this.sso = userProfile.sso
    this.customerId = userProfile.customerId
    this.customerModules = userProfile.customerModules
  }
}
