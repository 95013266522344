<template>
    <div id="app">
        <router-view name="header"></router-view>
        <notification-toast-vue group="app-notifications" :toasty="toasty"/>
        <main>
            <router-view v-slot="{ Component }">
                <keep-alive :include="['product-portfolio-page', 'tmds-view-page', 'market-portfolio-page']">
                    <component :is="Component" />
                </keep-alive>
            </router-view>
        </main>
        <router-view name="footer"></router-view>
    </div>
</template>
<script lang="ts">
import { hotjar } from '@/hotjar';
import store from './store';
import { defineComponent, computed, watch } from "vue";
import NotificationToastVue from './modules/common/components/argon/NotificationToast.vue';

export default defineComponent({
    components: {
        NotificationToastVue
    },
    setup() {

        const isLoggedIn = computed((): Boolean => {
            return store.state.$auth.isAuthenticated
        })

        watch(isLoggedIn, 
                () => {
                if (isLoggedIn.value) {
                    hotjar.hotjar()
                }
            },
            {
                deep: true,
                immediate: true
            }
        )

        const toasty = computed(() => {
            if (store.state.toastVisible) {
                return {
                    text: store.state.toastText,
                }
            }
            return "";
        })

        return {
            toasty
        }
    }
})
</script>
