import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b8847de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "org-container media pe-2 d-flex align-items-center justify-content-start" }
const _hoisted_2 = {
  key: 0,
  class: "spinner-container"
}
const _hoisted_3 = {
  key: 1,
  class: "d-flex align-items-center justify-content-start"
}
const _hoisted_4 = { class: "rounded-circle org-icon me-4" }
const _hoisted_5 = { class: "text-primary m-0 org-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spinner = _resolveComponent("spinner")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('select', _ctx.organisationId)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.organisation.isFetching)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_spinner)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("i", {
              class: _normalizeClass(["fa fa-circle me-1", {'white-bullet': !_ctx.isCurrentOrg}])
            }, null, 2),
            _withDirectives(_createElementVNode("img", _hoisted_4, null, 512), [
              [_directive_lazy, '/' + _ctx.organisation.iconUri]
            ]),
            _createElementVNode("h6", _hoisted_5, [
              _createElementVNode("b", null, _toDisplayString(_ctx.organisation.name), 1)
            ])
          ]))
    ])
  ]))
}