import * as Axios from 'axios';
import BaseService from '../../../framework/services/BaseService';
import store from '@/store';
import { ITodoItem } from '@/modules/products/domain/types';

export default class TodoService extends BaseService {
    public async getTodoItems(orgID: string): Promise<ITodoItem[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/organisations/${orgID}/todo`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as ITodoItem[];
    }
}
