<template>
    <header class="header-global">
        <base-nav class="navbar-main fixed-top py-0" type="primary" effect="dark" style="height:53px" expand>
            <router-link slot="brand" class="navbar-brand" to="/">
                <img src="/img/brand/pc-dark-flat.png" alt="logo">
            </router-link>
        </base-nav>
    </header>
</template>
<script lang="ts">
import BaseNav from '@/modules/common/components/argon/BaseNav.vue'
import { defineComponent } from "vue";

export default defineComponent({
    name: 'app-header-simple',
    components: {
        BaseNav,
    },
    setup() {
        return {}
    }
})
</script>
<style>
</style>
