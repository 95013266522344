import { FetchBase, fetchable } from '@/modules/common/domain'
import { IGovernanceWorkflowTemplate, IGovernanceWorkflowTemplates, IOutcome } from '@/modules/products/domain/types';
import { IFetchable } from '@/modules/common/domain/types/IFetchable';
import GovernanceWorkflowService from '@/modules/products/services/GovernanceWorkflowService'
import { workflow } from '@/vuex';

export class GovernanceWorkflowTemplate extends FetchBase implements IFetchable<IGovernanceWorkflowTemplate>, IGovernanceWorkflowTemplate {
    private governanceWorkflowService = new GovernanceWorkflowService();
    public createdAt?: string = "";
    public description = "";
    public reason = "";
    public duration = 0;
    public durationUnit = "";
    public expectedOutcome?: IOutcome[] = [];
    public organisationId?: string = "";
    public templateId?: string = "";
    public title = "";
    public userId?: string = "";
    @fetchable
    public async fetch(orgId: string, templateId: string) {
        const template: IGovernanceWorkflowTemplate = await this.governanceWorkflowService.getGovernanceWorkflowTemplate(orgId, templateId) as IGovernanceWorkflowTemplate;

        this.createdAt = template.createdAt;
        this.description = template.description;
        this.reason = template.reason;
        this.duration = template.duration;
        this.durationUnit = template.durationUnit;
        this.expectedOutcome = template.expectedOutcome;
        this.organisationId = template.organisationId;
        this.templateId = template.templateId
        this.title = template.title;
        this.userId = template.userId;
    }
}
