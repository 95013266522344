import * as Axios from 'axios';
import BaseService from '@/framework/services/BaseService';
import store from '@/store';

import { IRoadmap } from '@/modules/common/domain/types'

export default class RoadmapService extends BaseService {
    public async getAllRoadmaps(): Promise<IRoadmap[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/roadmaps`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IRoadmap[];
    }
}
