import { FetchBase, fetchable } from '@/modules/common/domain'
import { ITargetMarket, ITargetMarketPortfolio } from '@/modules/products/domain/types';
import TmdService from '@/modules/products/services/TmdService'
import { TargetMarketDetails } from './TargetMarketDetails';
import { IFetchable } from '@/modules/common/domain/types/IFetchable';

export class TargetMarketPortfolio extends FetchBase implements IFetchable<ITargetMarketPortfolio>, ITargetMarketPortfolio {
    public tmdCount = 0
    public publishedCount = 0
    public wipCount = 0
    public workflowCount = 0
    public tmds: ITargetMarket[] = []

    private targetMarketService = new TmdService();

    @fetchable
    public async fetch(orgId: string) {
        const tmds: ITargetMarket[] = await this.targetMarketService.getTargetMarketData(orgId) as ITargetMarket[];

        this.reset();

        for (let rawTMD of tmds) {
            let newTMD: TargetMarketDetails = new TargetMarketDetails()
            newTMD.tmdId = rawTMD.tmdId
            newTMD.marketCategory = rawTMD.marketCategory
            newTMD.name = rawTMD.name
            newTMD.isWip = rawTMD.isWip
            newTMD.isPublished = rawTMD.isPublished
            newTMD.workflowId = rawTMD.workflowId
            newTMD.productIds = rawTMD.productIds ? rawTMD.productIds : []
            newTMD.lastUpdated = rawTMD.lastUpdated ? rawTMD.lastUpdated : '';

            if (rawTMD.isWip) this.wipCount++
            if (rawTMD.isPublished) this.publishedCount++
            if (rawTMD.workflowId) this.workflowCount++

            this.tmds.push(newTMD)
        }
        this.tmdCount = this.tmds.length
    }

    private reset() {
        this.tmdCount = 0
        this.publishedCount = 0
        this.wipCount = 0
        this.workflowCount = 0
        this.tmds = []
    }
}
