import { FetchBase, fetchable } from '@/modules/common/domain'
import WorkflowService from '@/modules/products/services/WorkflowService';
import { IWorkflow, IWorkflows } from '@/modules/products/domain/types';
import { IFetchable } from '@/modules/common/domain/types/IFetchable';

export class WorkflowForProduct extends FetchBase implements IFetchable<IWorkflows>, IWorkflows {
    public workflows: IWorkflow[] = [];

    private workflowService = new WorkflowService();

    @fetchable
    public async fetch(orgID: string) {
        let workflows: IWorkflow[] = [];
        workflows = await this.workflowService.getWorkflows(orgID)
        this.workflows = workflows;
    }
}
