import store from '@/store';
import { RouteRecordRaw, NavigationGuard } from 'vue-router';
import { IModule } from '@/framework/domain/types/IModule';
import AppFooter from "@/framework/components/layout/AppFooter.vue";

export class RiskModule implements IModule {

    public name = 'risk';
    public title = 'RISK MANAGEMENT';
    public description = 'Identify, manage and monitor enterprise risks';
    public landingRoute = '/risk/landing';

    public isCurrent(route: string): boolean {
        if (/^\/risk/.test(route)) return true;
        return false;
    }

    public isModuleEnabledForUser(): boolean {
        return true;//XXXX
    }

    public routes(guardSecure: NavigationGuard, guardPublic: NavigationGuard): RouteRecordRaw[] {
        return [
            {
                path: "/risk/landing",
                beforeEnter: guardSecure,
                name: "RiskLanding",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/Landing.vue"),
                    footer: AppFooter,
                },
            },    
        ]
        
    }
}


