<template>
  <div>
    <ul class="spin-button form-control" :id="id" :class="{'disabled': disabled}">
      <li @click="decrement" :class="[{ 'disable': inputValue <= min }, 'data-v']">
        -
      </li>
      <li class="data-v border-left border-right">
        {{ inputValue }}
      </li>
      <li  @click="increment" :class="[{ 'disable': inputValue >= max },'data-v']">
        +
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue"

export default defineComponent({
  name: 'form-spinbutton',
  props: {
    id: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Number,
      default: 0
    },
    min: {
      type: Number,
      default: Number.MIN_SAFE_INTEGER,
    },
    max: {
      type: Number,
      default: Number.MAX_SAFE_INTEGER,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const inputValue = ref(props.modelValue)

    const updateValue = () => {
      emit("update:modelValue", inputValue.value);
    }

    watch(()=>props.modelValue, (newValue) => {
        inputValue.value = newValue
      },
      {
        deep: true,
        immediate: true
      }
    )

    const increment = () => {
      if (inputValue.value < props.max) {
        inputValue.value += 1
      }
      updateValue()
    };

    const decrement = () => {
      if (inputValue.value > props.min) {
        inputValue.value -= 1;
      }
      updateValue()
    };

    return { 
      inputValue,
      increment, 
      decrement 
    };
  },

})

</script>

<style scoped lang="scss">
.spin-button {
  list-style: none;
  padding: 4px 0;
  display: flex;
  align-items: center;
  margin: 0;
}

.spin-button li {
  cursor: pointer;
  padding: 1px 10px;
  margin: 0;
  user-select: none;
  min-width: 36px;
  text-align: center;
}

.spin-button li.disable {
  pointer-events: none;
  background-color: #fff;
}

.border-left {
  border-left: 0.0625rem solid #e9ecef!important;
}

.border-right {
    border-right: 0.0625rem solid #e9ecef!important;
}
.disabled {
  pointer-events: none;
  background-color: #e9ecef;
}

</style>
