import * as Generated from '@/modules/products/domain/generated'
import { FetchBase, fetchable } from '@/modules/common/domain'
import IndustryService from '@/modules/products/services/IndustryService';
import { IMarketProduct } from '@/modules/products/domain/types';
import { IFetchable } from '@/modules/common/domain/types/IFetchable';

export class MarketProduct extends FetchBase implements IFetchable<IMarketProduct>, IMarketProduct {
    public product?: Generated.Product;

    private industryService: IndustryService = new IndustryService();

    @fetchable
    public async fetch(organisationID: string, productID: string) : Promise<void> {
        this.product = await this.industryService.getMarketProduct(organisationID, productID) as any;
    }
}
