import store from '@/store';
import { FetchBase, fetchable } from '@/modules/common/domain'
import TmdWorkflowService from '@/modules/products/services/TmdWorkflowService';
import { ITmdReviews, ITargetMarketWorkflow, WorkflowState } from '@/modules/products/domain/types';
import { IFetchable } from '@/modules/common/domain/types/IFetchable';

export class TmdReviewsList extends FetchBase implements IFetchable<ITmdReviews>, ITmdReviews {
    public assigned: ITargetMarketWorkflow[] = []
    public created: ITargetMarketWorkflow[] = []

    private tmdWorkflowService = new TmdWorkflowService();

    @fetchable
    public async fetch(orgID: string) {
        let workflows: ITargetMarketWorkflow[] = await this.tmdWorkflowService.getAllTmdWorkflows(orgID);
        this.assigned = [];
        this.created = [];

        for (let i = 0; i < workflows.length; i++) {
            if (workflows[i].state !== WorkflowState.CLOSED) {
                if (workflows[i].assignedTo === store.state.$auth.user.userId) {
                    this.assigned.push(workflows[i]);
                } else if (workflows[i].userId === store.state.$auth.user.userId) {
                    this.created.push(workflows[i]);
                }
            }
        }
    }
}
