import * as Axios from 'axios';
import BaseService from '../../../framework/services/BaseService';
import store from '@/store';
import { IInternalProductTabs, IOrganisation, IOrgSettings, IOrganisationSettingsEvent } from '@/modules/products/domain/types';

export default class OrganisationService extends BaseService {
    public async getOrganisation(orgID: string): Promise<IOrganisation> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/organisations/${orgID}`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IOrganisation;
    }

    public async getSettings(orgID: string): Promise<IOrgSettings> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/organisations/${orgID}/admin/settings`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return this.parseOrgSettings(res.data);
    }

    public async getSettingsHistory(orgID: string): Promise<IOrganisationSettingsEvent[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/organisations/${orgID}/admin/settings/events`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IOrganisationSettingsEvent[];
    }

    public async restoreSettings(orgID: string, settings: IOrgSettings): Promise<IOrgSettings> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'post',
            url: `${store.state.apiBase}/private/organisations/${orgID}/admin/settings/restored`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            data: {
                value : settings
            }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        if (res.status === 200) {
            store.state.toastVisible = true;
            store.state.toastText = "Restored settings version."
        }
        return this.parseOrgSettings(res.data);
    }

    public async setSecuritySettings(orgID: string, settings: IOrgSettings): Promise<IOrgSettings> {
        let publicKey: string | undefined;
        let publicKeys: any[] | undefined;
        let approvedDomain: string | undefined;

        if (settings.security) {
            publicKey = settings.security.publicKey;
            publicKeys = settings.security.publicKeys;
            approvedDomain = settings.security.approvedDomain;
        }

        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'post',
            url: `${store.state.apiBase}/private/organisations/${orgID}/admin/settings/security`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            data: {
                value: {
                    publicKey: publicKey,
                    publicKeys: publicKeys,
                    approvedDomain: approvedDomain
                }
            }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        if (res.status === 200) {
            store.state.toastVisible = true;
            store.state.toastText = "Updated security settings."
        }
        return this.parseOrgSettings(res.data);
    }

    public async setWorkflowSettings(orgID: string, settings: IOrgSettings): Promise<IOrgSettings> {
        let productAutoCompleteOnApproval: boolean | undefined;
        let productDisallowApproveOwnWorkflow: boolean | undefined;
        let productEmailNotification: boolean | undefined;
        let tmdAutoCompleteOnApproval: boolean | undefined;
        let tmdDisallowApproveOwnWorkflow: boolean | undefined;
        let tmdEmailNotification: boolean | undefined;

        if (settings.workflow) {
            productAutoCompleteOnApproval = settings.workflow.productAutoCompleteOnApproval;
            productDisallowApproveOwnWorkflow = settings.workflow.productDisallowApproveOwnWorkflow;
            productEmailNotification = settings.workflow.productEmailNotification;
            tmdAutoCompleteOnApproval = settings.workflow.tmdAutoCompleteOnApproval;
            tmdDisallowApproveOwnWorkflow = settings.workflow.tmdDisallowApproveOwnWorkflow;
            tmdEmailNotification = settings.workflow.tmdEmailNotification;
        }

        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'post',
            url: `${store.state.apiBase}/private/organisations/${orgID}/admin/settings/workflow`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            data: {
                value: {
                    productAutoCompleteOnApproval: productAutoCompleteOnApproval,
                    productDisallowApproveOwnWorkflow: productDisallowApproveOwnWorkflow,
                    productEmailNotification: productEmailNotification,
                    tmdAutoCompleteOnApproval: tmdAutoCompleteOnApproval,
                    tmdDisallowApproveOwnWorkflow: tmdDisallowApproveOwnWorkflow,
                    tmdEmailNotification: tmdEmailNotification
                }
            }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        if (res.status === 200) {
            store.state.toastVisible = true;
            store.state.toastText = "Updated workflow policies."
        }
        return this.parseOrgSettings(res.data);
    }

    public async setGovWorkflowSettings(orgID: string, settings: IOrgSettings): Promise<IOrgSettings> {
        let notifyBeforeDueDay: any;
        let notifyOnNoStart: any;
        let notifyOnDueDay: boolean | undefined;
        let notifyAfterDue: boolean | undefined;
        let emailNotification: boolean | undefined;
        let usersToNotify: string[] | undefined;

        if (settings.govWorkflow) {
            notifyBeforeDueDay = settings.govWorkflow.notifyBeforeDueDay;
            notifyOnNoStart = settings.govWorkflow.notifyOnNoStart;
            notifyOnDueDay = settings.govWorkflow.notifyOnDueDay;
            notifyAfterDue = settings.govWorkflow.notifyAfterDue;
            emailNotification = settings.govWorkflow.emailNotification;
            usersToNotify = settings.govWorkflow.usersToNotify;
        }

        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'post',
            url: `${store.state.apiBase}/private/organisations/${orgID}/admin/settings/govWorkflow`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            data: {
                value: {
                    notifyBeforeDueDay: notifyBeforeDueDay,
                    notifyOnNoStart: notifyOnNoStart,
                    notifyOnDueDay: notifyOnDueDay,
                    notifyAfterDue: notifyAfterDue,
                    emailNotification: emailNotification,
                    usersToNotify: usersToNotify
                }
            }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        if (res.status === 200) {
            store.state.toastVisible = true;
            store.state.toastText = "Updated governance workflow settings."
        }
        return this.parseOrgSettings(res.data);
    }

    public async setExtractSettings(orgID: string, settings: IOrgSettings): Promise<IOrgSettings> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'post',
            url: `${store.state.apiBase}/private/organisations/${orgID}/admin/settings/extract`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            data: {
                value: settings.extract
            }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        if (res.status === 200) {
            store.state.toastVisible = true;
            store.state.toastText = "Updated automatic extract configuration."
        }
        return this.parseOrgSettings(res.data);
    }

    public async setCalculationSettings(orgID: string, settings: IOrgSettings): Promise<IOrgSettings> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'post',
            url: `${store.state.apiBase}/private/organisations/${orgID}/admin/settings/calculation`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            data: {
                value: settings.calculation
            }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        if (res.status === 200) {
            store.state.toastVisible = true;
            store.state.toastText = "Updated 3rd party calculation configuration."
        }
        return this.parseOrgSettings(res.data);
    }

    public async setValidationSettings(orgID: string, settings: IOrgSettings): Promise<IOrgSettings> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'post',
            url: `${store.state.apiBase}/private/organisations/${orgID}/admin/settings/validation`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            data: {
                value: settings.validation
            }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        if (res.status === 200) {
            store.state.toastVisible = true;
            store.state.toastText = "Updated 3rd party validation configuration."
        }
        return this.parseOrgSettings(res.data);
    }

    public async setBrandsSettings(orgID: string, settings: IOrgSettings): Promise<IOrgSettings> {
        let enforceBrands = false;
        let brands: any[] | undefined;

        if (settings.brands) {
            if (settings.brands.enforceBrands) {
                enforceBrands = settings.brands.enforceBrands;
                brands = settings.brands.brands;
            }
        }

        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'post',
            url: `${store.state.apiBase}/private/organisations/${orgID}/admin/settings/brands`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            data: {
                value: {
                    enforceBrands: enforceBrands,
                    brands: brands
                }
            }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        if (res.status === 200) {
            store.state.toastText = "Added a new brand for assignment to products in this organisation."
            store.state.toastVisible = true
        }
        return this.parseOrgSettings(res.data);
    }

    public async setCustomTagsSettings(orgID: string, settings: IOrgSettings): Promise<IOrgSettings> {
        let tags: any[] = [];

        if (settings.customTags && settings.customTags.tags) {
            tags = settings.customTags.tags;
        }

        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'post',
            url: `${store.state.apiBase}/private/organisations/${orgID}/admin/settings/customTags`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            data: {
                value: {
                    tags: tags
                }
            }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        if (res.status === 200) {
            store.state.toastVisible = true;
            store.state.toastText = "Updated custom tags settings."
        }
        return this.parseOrgSettings(res.data);
    }

    public async setSectionTagsSettings(orgID: string, settings: IOrgSettings): Promise<IOrgSettings> {
        let tags: any[] = [];

        if (settings.sectionTags && settings.sectionTags.tags) {
            tags = settings.sectionTags.tags;
        }

        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'post',
            url: `${store.state.apiBase}/private/organisations/${orgID}/admin/settings/sectionTags`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            data: {
                value: {
                    tags: tags
                }
            }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        if (res.status === 200) {
            store.state.toastVisible = true;
            store.state.toastText = "Updated internal fields settings."
        }
        return this.parseOrgSettings(res.data);
    }

    private parseOrgSettings(data: any): IOrgSettings {
        const result: IOrgSettings = {};

        if (data.settings && data.settings.security) result.security = data.settings.security;
        if (data.settings && data.settings.workflow) result.workflow = data.settings.workflow;
        if (data.settings && data.settings.govWorkflow) result.govWorkflow = data.settings.govWorkflow;
        if (data.settings && data.settings.extract) result.extract = data.settings.extract;
        if (data.settings && data.settings.calculation) result.calculation = data.settings.calculation;
        if (data.settings && data.settings.validation) result.validation = data.settings.validation;
        if (data.settings && data.settings.brands) result.brands = data.settings.brands;
        if (data.settings && data.settings.customTags) result.customTags = data.settings.customTags;
        if (data.settings && data.settings.sectionTags) result.sectionTags = data.settings.sectionTags;
        if (data.settings && data.settings.internalProductCategories) result.internalProductCategories = data.settings.internalProductCategories;
        if (data.settings && data.settings.cdrProductCategories) result.cdrProductCategories = data.settings.cdrProductCategories;
        if (data.settings && data.settings.internalProductTabs) result.internalProductTabs = data.settings.internalProductTabs;
        if (data.settings && data.settings.sync) result.sync = data.settings.sync;
        if (data.settings && data.settings.tmdSchema) result.tmdSchema = data.settings.tmdSchema;
        return result;
    }

    public async setInternalProductCategories(orgID: string, settings:IOrgSettings) {
        let internalProductCategories: any = {}

        if (settings.internalProductCategories) {
            internalProductCategories = settings.internalProductCategories.productCategories;
        }

        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'post',
            url: `${store.state.apiBase}/private/organisations/${orgID}/admin/settings/internalProductCategories`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            data: {
                value: {
                    productCategories: internalProductCategories
                }
            }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        if (res.status === 200) {
            store.state.toastVisible = true;
            store.state.toastText = "Updated Internal Product Categories."
        }
        return this.parseOrgSettings(res.data);
    }

    public async setCdrProductCategories(orgID: string, settings:IOrgSettings) {
        let cdrProductCategories: any = {}

        if (settings.cdrProductCategories) {
            cdrProductCategories = settings.cdrProductCategories.productCategories;
        }

        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'post',
            url: `${store.state.apiBase}/private/organisations/${orgID}/admin/settings/cdrProductCategories`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            data: {
                value: {
                    productCategories: cdrProductCategories
                }
            }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        if (res.status === 200) {
            store.state.toastVisible = true;
            store.state.toastText = "Updated CDR Product Categories."
        }
        return this.parseOrgSettings(res.data);
    }

    public async setInternalProductTabs(orgID: string, settings:IOrgSettings) {
        let internalProductTabs: IInternalProductTabs = {}

        if (settings.internalProductTabs) {
            internalProductTabs = settings.internalProductTabs.tabs;
        }

        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'post',
            url: `${store.state.apiBase}/private/organisations/${orgID}/admin/settings/internalProductTabs`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            data: {
                value: {
                    tabs: internalProductTabs
                }
            }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        if (res.status === 200) {
            store.state.toastVisible = true;
            store.state.toastText = "Updated Internal Product Tabs."
        }
        return this.parseOrgSettings(res.data);
    }

    public async setTmdSchema(orgID: string, settings:IOrgSettings) {
        let schema: any = {}

        if (settings.tmdSchema) {
            schema = settings.tmdSchema;
        }

        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'post',
            url: `${store.state.apiBase}/private/organisations/${orgID}/admin/settings/tmdSchema`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            data: {
                value: {
                    categories: schema.categories,
                    sections: schema.sections
                }
            }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        if (res.status === 200) {
            store.state.toastVisible = true;
            store.state.toastText = "Updated target market configuration settings."
        }
        return this.parseOrgSettings(res.data);
    }

    public async setSyncSettings(orgID: string, value: any): Promise<any> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'post',
            url: `${store.state.apiBase}/private/organisations/${orgID}/admin/settings/sync`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            data: {
                value: value.sync
            }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        if (res.status === 200) {
            store.state.toastText = "Updated product synchronisation settings."
            store.state.toastVisible = true
        }
        return this.parseOrgSettings(res.data);
    }
}
