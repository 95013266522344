import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, vModelDynamic as _vModelDynamic, withKeys as _withKeys, withDirectives as _withDirectives, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e8f9485"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "row mb-1"
}
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = ["placeholder", "autocomplete", "disabled", "type", "min", "max"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_conditional_input_icon = _resolveComponent("conditional-input-icon")!
  const _component_warning_icon = _resolveComponent("warning-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.slots.label || _ctx.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "label", {}, () => [
              _createElementVNode("span", {
                class: _normalizeClass(['data-h', {'data-mandatory': _ctx.required}])
              }, _toDisplayString(_ctx.label), 3)
            ], true),
            (_ctx.showConditionalIcon)
              ? (_openBlock(), _createBlock(_component_conditional_input_icon, {
                  key: 0,
                  tooltipTitle: _ctx.conditionalIconTitle
                }, null, 8, ["tooltipTitle"]))
              : _createCommentVNode("", true),
            (_ctx.isWarning)
              ? (_openBlock(), _createBlock(_component_warning_icon, {
                  key: 1,
                  toolTipTitle: _ctx.warningTooltip,
                  appendedTitle: _ctx.appendTitle
                }, null, 8, ["toolTipTitle", "appendedTitle"]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("input", {
          class: _normalizeClass([
          'data-v',
          'form-control',
          `form-control-${_ctx.size}`,
          _ctx.inputClass,
          _ctx.rateChanged ? 'changed-input' : '',
          { 'error-border': _ctx.highlightError }
        ]),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputModel) = $event)),
          placeholder: _ctx.placeholder,
          autocomplete: _ctx.autocomplete,
          disabled: _ctx.disabled,
          type: _ctx.type,
          min: _ctx.min,
          max: _ctx.max,
          onKeydown: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.keyDownEnter && _ctx.keyDownEnter(...args)), ["enter"])),
          onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
          onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
          onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onChange($event))),
          onInput: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onInput($event)))
        }, null, 42, _hoisted_5), [
          [_vModelDynamic, _ctx.inputModel]
        ])
      ])
    ])
  ], 64))
}