import { FetchBase, fetchable } from '../../common/domain/FetchBase'
import FeeService from '@/modules/products/services/FeeService';
import { IFetchable } from '@/modules/common/domain/types';
import { IFees } from './types';

export class Fees extends FetchBase implements IFetchable<IFees>, IFees {
    public fees = []

    private feeService = new FeeService();

    @fetchable
    public async fetch(orgID: string) {
        let data = await this.feeService.getFees(orgID);
        this.fees = data
    }
}
