<template>
    <notifications group="app-notifications" position="top right">
        <template #body="props">
            <div class="body">
                <div class="toast-header">
                    <a>
                    {{props.item.title}}
                    </a>
                    <a class="close" @click="props.close">
                    <i class="fa fa-fw fa-close"></i>
                    </a>
                </div>
                <div class="toast-body">
                    <div v-html="props.item.text">
                </div>
                </div>
            </div>
        </template>
    </notifications>
</template>
<script lang="ts">

import { defineComponent, watch } from "vue";
import { notify } from "@kyvg/vue3-notification";
import store from "@/store";

export default defineComponent({
    props: {
        toasty: null
    },
    setup(props) {

        watch (() => props.toasty, 
            () => {
                if (store.state.toastVisible) {
                    notify({
                        group: "app-notifications",
                        title: '',
                        text: props.toasty.text
                    });
                    setTimeout(() => { 
                        hideToast()
                    }, 2000)
                }
            }
        )

        function hideToast() {
            store.state.toastVisible = false;
        }

        return {
        }
    }
})
</script>

<style scoped>
.body {
    background-color: hsla(0,0%,100%,.85);
    font-size: 1rem;
}
.toast-header {
    display: flex;
    justify-content: space-between;
    padding: 0.25rem 0.75rem;
    color: #6c757d;
    background-color: hsla(0,0%,100%,.85);
    border-bottom: 1px solid rgba(0,0,0,.05);
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
}

.close {
    float: right;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1;
    color: grey;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    cursor: pointer;
}

.toast-body {
    font-size: .875rem;
    padding: 12px
}
</style>
