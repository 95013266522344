import _Vue from 'vue'
import clickOutside from '../directives/argon/click-outside'
import { App } from 'vue'
import tooltip from '../directives/tooltip';

/**
 * You can register global directives here and use them as a plugin in your main Vue instance
 */

const GlobalDirectives = {
    install(app: App) {
        app.directive('click-outside', clickOutside)
        app.directive('tooltip', tooltip)
    }
}

export default GlobalDirectives
