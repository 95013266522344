import * as Axios from 'axios';
import store from '@/store'

export default class BaseService {
    protected async makeAxiosCall(config: Axios.AxiosRequestConfig): Promise<Axios.AxiosResponse> {
        try {
            // TODO: Add retry logic to retry after fetching a new token when the old token has expired
            return await Axios.default(config)
        } catch (err:any) {
            if (err.response) {
                if (err.response.status === 401) {
                    store.state.$auth.logout();
                }
            }
            throw err;
        }
    }
}
