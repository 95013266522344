import * as Axios from 'axios';
import store from '@/store';
import { IMarketReport, ReportCategory } from '@/modules/products/domain/types';

export default class MarketReportService {
    public async getStandardReports(reportCategory: ReportCategory, date: Date): Promise<IMarketReport[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/industry/report`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            params: { reportCategory, date }
        }

        const res: Axios.AxiosResponse = await Axios.default(apiConfig)
        return res.data as IMarketReport[];
    }
}
