export interface ILendingRatesReportFilters {
    orgs: string[];
    products?: string[];
    rateType?: string;
    lvr: string;
    loanPurpose: string;
    repaymentType: string;
    rate: Rate
}

export enum Rate {
    BASE = 'BASE',
    COMPARISON = 'COMPARISON',
    BASE_COMPARISON = 'BASE_COMPARISON'
}