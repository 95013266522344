<template>
    <i class="fa fa-question-circle-o info-icon" v-tooltip="tooltipTitle"/>
</template>

<script lang="ts">
import { defineComponent} from "vue";

export default defineComponent({
    props: {
        tooltipTitle: {
            type: String,
            default: ''
        }
    },
    setup() {
        return {}
    }
})
</script>
<style scoped lang="scss">
@import "~bootstrap/scss/functions";
@import "@/assets/scss/custom/_functions";
@import "@/assets/scss/custom/_variables";
// @import "@/assets/scss/bootstrap/_variables";
    .info-icon {
        color: $primary;
        cursor: pointer;
        font-size: $font-size-sm;
        margin-left: 3px;
    }
</style>
