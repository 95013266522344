import { FetchBase, fetchable } from '@/modules/common/domain'
import IndustryService from '@/modules/products/services/IndustryService';
import { IMarketChangeSummary } from '@/modules/products/domain/types';
import { IFetchable } from '@/modules/common/domain/types/IFetchable';


export class MarketChangeSummary extends FetchBase implements IFetchable<IMarketChangeSummary>, IMarketChangeSummary {
    public startDate = '';
    public endDate = '';
    public counts: any = {};

    private industryService = new IndustryService();

    @fetchable
    public async fetch(startDate?: string, endDate?: string) {
        let summary = await this.industryService.getMarketChangeSummary(startDate, endDate);
        this.startDate = summary.startDate;
        this.endDate = summary.endDate;
        this.counts = summary.counts;
    }
}
