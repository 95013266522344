export default {
    getWorkflowsAssignedToUser(state: any, payload: any) {
        state.assignedProductWorkflows = payload.productWorkflows
        state.assignedTmdWorkflows = payload.tmdWorkflows
    },
    getProductWorkflowsAssignedToUser(state: any, payload: any) {
        state.assignedProductWorkflows = payload.productWorkflows
    },
    getTmdWorkflowsAssignedToUser(state: any, payload: any) {
        state.assignedTmdWorkflows = payload.tmdWorkflows
    }
}
