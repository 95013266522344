export interface IEmailPreference {
    userId: string;
    frequency: EmailFrequencyType;
    orgPreference: EmailOrgPrefType;
    orgWorkflowPreference?: {
        [orgId: string]: boolean
    }
}

export enum EmailFrequencyType {
    NEVER = 'NEVER',
    DAILY = 'DAILY',
    WEEKLY = 'WEEKLY',
    MONTHLY = 'MONTHLY'
}

export enum EmailOrgPrefType {
    ALL = 'ALL',
    NOTIONLY = 'NOTIONLY'
}
