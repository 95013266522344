import { FetchBase, fetchable } from '@/modules/common/domain'
import { IRoadmap, IFetchable } from '@/modules/common/domain/types';
import RoadmapService from '@/modules/common/services/RoadmapService';

export class Roadmaps extends FetchBase implements IFetchable<IRoadmap[]> {
    public roadmaps: IRoadmap[] = [];

    private roadmapService = new RoadmapService();

    @fetchable
    public async fetch() {
        this.roadmaps = await this.roadmapService.getAllRoadmaps();
    }
}
