<template>
  <div>
    <div class="pc-tabs">
      <ul class="nav nav-tabs">
        <li class="nav-item" v-for="(tab, index) in tabs" :key="index">
          <a
            href="#"
            class="nav-link"
            :class="{ 'active': tab.isActive }"
            @click="activateTab(index, tabIndex)"
          >
          <span class="d-flex align-items-center justify-content-center">
            {{ tab.title }}
              <WarningOutlined class="tab-icon-close" v-if="tab.hasWarning"/>
          </span>
          </a>
        </li>
      </ul>
    </div>
    <div class="tab-content">
      <div ref="fieldTabs">
        <slot :tabIndex="tabIndex" ></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, provide, watch, onMounted, onUpdated } from 'vue';
import { WarningOutlined } from '@ant-design/icons-vue';

export default defineComponent({
  name: 'field-tabs',
  props: {
    modelValue: {
      type: Number
    },
    activeTab: {
      type: String,
      default: ''
    }
  },
  components: {
    WarningOutlined
  },
  emits: ['update:modelValue', 'activate-tab'],
  setup(props, {emit}) {
    const tabIndex = ref(props.modelValue !== undefined ? props.modelValue : 0);
    const tabs = ref<any[]>([]);
    const oldIndex = ref();
    const fieldTabs = ref()

    onMounted(() => {
      if (tabs.value.length > 0 && tabIndex.value === 0) {
        activateTab(0);
      }
    });

    const addTab = (tab: any, initiallyActive: Boolean) => {
      if ( props.activeTab && props.activeTab === tab.title) {
        activateTab(0, oldIndex.value)
      }
      
      tabs.value.push(tab);
      if (initiallyActive) {
        activateTab(tabs.value.length - 1, oldIndex.value);
      }
    };

    const updateTab = (tabChanges: any) => {
      let tabIndex = tabs.value.findIndex(tab => tab.tabId === tabChanges.tabId)
      if (tabIndex > -1 && tabs.value[tabIndex].hasOwnProperty('hasWarning')) {
        tabs.value[tabIndex].hasWarning = tabChanges.hasWarning
      }
    }

    watch(
      () => props.modelValue,
      (newValue, oldvalue) => {
        oldIndex.value = tabIndex.value;
        activateTab(newValue, oldIndex.value)
      }
    );

    const activateTab = (index: any, old?: any) => {
      if (index >= 0 && index < tabs.value.length ) {
        tabIndex.value = index;
        tabs.value.forEach((tab, i) => (tab.isActive = i === index));
        emit('update:modelValue',index);
        emit('activate-tab',index, old)
      }
    };

    provide('tabsContainer', { addTab, updateTab });

    return {
      tabs,
      activateTab,
      tabIndex,
      fieldTabs
    };
  },
});
</script>

<style scoped lang="scss">
@import "~bootstrap/scss/functions";
@import "@/assets/scss/custom/_functions";
@import "@/assets/scss/custom/_variables";
.nav-link {
  background-color: #efefef;
  border-color: #ddd;
}

.tab-icon-close {
    color: $danger;
    margin-left: 5px;
}
</style>