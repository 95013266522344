import store from '@/store';
import { RouteRecordRaw, NavigationGuard } from 'vue-router';
import { IModule } from '@/framework/domain/types/IModule';
import AppFooter from "@/framework/components/layout/AppFooter.vue";

export class SupplierModule implements IModule {

    public name = 'supplier';
    public title = 'SUPPLIER MANAGEMENT';
    public description = 'Manage the initial, and ongoing, compliance of suppliers';
    public landingRoute = '/supplier/landing';

    public isCurrent(route: string): boolean {
        if (/^\/supplier/.test(route)) return true;
        return false;
    }

    public isModuleEnabledForUser(): boolean {
        return true;//XXXX
    }

    public routes(guardSecure: NavigationGuard, guardPublic: NavigationGuard): RouteRecordRaw[] {
        return [
            {
                path: "/supplier/landing",
                beforeEnter: guardSecure,
                name: "SupplierLanding",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/Landing.vue"),
                    footer: AppFooter,
                },
            },    
        ]
        
    }
}


