import { FetchBase, fetchable } from '@/modules/common/domain'
import ProductValidationService from '@/modules/products/services/ProductsValidationService';
import { ProductsValidationStatus, ProductsValidationCategory, IValidationEvent } from '@/modules/products/domain/types';
import { IFetchable } from '@/modules/common/domain/types/IFetchable';

export class ValidationEvent extends FetchBase implements IFetchable<IValidationEvent>, IValidationEvent {
    public eventId: string = "";
    public organisationId: string = "";
    public status?: ProductsValidationStatus;
    public data?: any;
    public eventCategory?: ProductsValidationCategory[];
    public createdAt?: string | undefined;
    public updatedAt?: string | undefined;

    private validationService = new ProductValidationService();

    @fetchable
    public async fetch(orgID: string, eventId: string) {
        let data = await this.validationService.getValidationEvent(orgID , eventId);
        this.eventId = data.eventId;
        this.organisationId = data.organisationId;
        this.status = data.status;
        this.data = data.data;
        this.eventCategory = data.eventCategory;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;
    }
}
