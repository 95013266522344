import * as Axios from 'axios';
import BaseService from '../../../framework/services/BaseService';
import store from '@/store';
import { IUserProfile, IUserBrief, IResetPasswordResponse, IUserFull } from '@/modules/common/domain/types';

const userMap: { [key: string]: IUserBrief } = {};

export default class UserProfileService extends BaseService {
    public async getUserProfile(): Promise<any> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/profile`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IUserProfile;
    }

    public async getResetPasswordURI(): Promise<IResetPasswordResponse> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/profile/resetpassword`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IResetPasswordResponse;
    }

    public async updatePassword(oldPassword:string, newPassword:string, username: string): Promise<any> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'put',
            url: `${store.state.apiBase}/private/profile/changePassword`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            data: {
                oldPassword: oldPassword,
                newPassword: newPassword,
                username: username
            }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as any;
    }

    public async updateUserWhatsNewId(whatsNewId:string) :Promise<any> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'post',
            url: `${store.state.apiBase}/private/profile/whatsNew/${whatsNewId}`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data
    }

    public async disableMfa(otp: string) :Promise<boolean> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'delete',
            url: `${store.state.apiBase}/private/mfa`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            data: {
                token: otp
            }
        }

        const res: Axios.AxiosResponse = await Axios.default(apiConfig)
        return res.status === 204
    }

    public async initiateMfa() :Promise<string> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'post',
            url: `${store.state.apiBase}/private/mfa`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await Axios.default(apiConfig)
        if (!res.data || !res.data.uri) throw Error('Failed to initiate MFA setup')
        return res.data.uri as string;
    }

    public async validateMfaSetup(otp: string) :Promise<string> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'post',
            url: `${store.state.apiBase}/private/mfa/validate`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            data: {
              token: otp
            }
        }

        const res: Axios.AxiosResponse = await Axios.default(apiConfig)
        if (!res.data || !res.data.token) throw Error('Failed to validate MFA verification code on setup')
        return res.data.token;
    }

    public async getUserBrief(orgId: string, userId: string): Promise<IUserBrief> {
        // Return static data for internal automation user ID
        if (userId === 'INTERNAL') {
            return {
                userId: userId,
                firstName: 'Automated',
                lastName: 'System'
            };
        }

        // Return static data for integration partners
        if (userId === 'WIDGET_WORKS') {
            return {
                userId: userId,
                firstName: 'Widget',
                lastName: 'Works'
            };
        }

        if (typeof userMap[userId] !== 'undefined') return userMap[userId];

        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/organisations/${orgId}/users/${userId}`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)

        userMap[userId] = res.data as IUserBrief;
        return res.data as IUserBrief;
    }

    public async getUsersByRole(orgId: string, role: string): Promise<IUserBrief[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/organisations/${orgId}/users`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            params: { role: role }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)

        return res.data as IUserBrief[];
    }

    public async getUsersOfOrganisation(orgId: string): Promise<IUserFull[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/organisations/${orgId}/users/all`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)

        return res.data as IUserFull[];
    }
}
