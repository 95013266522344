<template>
  <div>
    <div :class="['text-left']">
      <div :href="`#${id}`" :class="[headerClass, customClass, {'collapsed': !visible}]" data-bs-toggle="collapse" :aria-expanded="visible" class="border-bottom navigation local-collapse">
          <i class="fa fa-fw fa-chevron-down opened"></i>
          <i class="fa fa-fw fa-chevron-right closed"></i>
            <span v-if="heading" class="h5 font-weight-400 ms-2">
              {{ heading }}
            </span>
            <slot v-else name="headingSlot">
            </slot>
      </div>
    </div>
    <div :id="id" class="collapsible collapse" :class="{'show': visible}">
      <slot></slot>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue"

export default defineComponent({
  name: 'base-collapse',
  props: {
    id: {
      type: String,
      required: true
    },
    heading: {
      type: String
    },
    headerClass: {
      type: String
    },
    customClass:{
      type: String,
      default:""
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const isOpen = ref(props.visible)

    function toggleCollapse() {
      isOpen.value = !isOpen.value;
    }

    return {
      isOpen,
      toggleCollapse
    }
  }
})
</script>
<style scoped lang="scss">
@import "~bootstrap/scss/functions";
@import "@/assets/scss/custom/_functions";
@import "@/assets/scss/custom/_variables";
@import "~bootstrap/scss/variables";

.collapsible {
    width: 100%;
    margin: 0;
    padding: 0;
}

.collapse-button {
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
}
.collapse-button:focus {
    outline:none
}

.collapsed .opened,
.not-collapsed .closed {
    display: none;
}
.customStyle {
    background-color: #e9ecef ;
    padding: 1rem;
    width: 100% !important;
}
</style>
