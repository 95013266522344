import { FetchBase, fetchable } from '@/modules/common/domain'
import MarketReportService from '@/modules/products/services/MarketReportService';
import { IMarketReport, IMarketReports, ReportCategory } from '@/modules/products/domain/types';
import { IFetchable } from '@/modules/common/domain/types/IFetchable';

export class MarketReport extends FetchBase implements IFetchable<IMarketReports>, IMarketReports {
    public reports: IMarketReport[] = [];

    private marketReportService = new MarketReportService();

    @fetchable
    public async fetch(reportCategory: ReportCategory, date: Date) {
        this.reports = await this.marketReportService.getStandardReports(reportCategory,date);
    }
}
