<template>
  <div class="card card-box"
       :class="[
       {'card-lift--hover': hover},
       {'shadow': shadow},
       {[`bg-gradient-${gradient}`]: gradient},
       {[`bg-${type}`]: type}
     ]">
      <slot></slot>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
  name: 'base-card',
  props: {
      type: {
          type: String,
          description: 'Card type'
      },
      gradient: {
          type: String,
          description: 'Card background gradient type (warning,danger etc)'
      },
      hover: {
          type: Boolean,
          description: 'Whether card should move on hover'
      },
      shadow: {
          type: Boolean,
          description: 'Whether card has shadow'
      },
      bodyClasses: {
          type: [String, Object, Array],
          description: 'Card body css classes'
      },
  },
  setup() {
    return {

    }
  }
})
</script>
<style scoped lang="scss">
@import "~bootstrap/scss/functions";
@import "@/assets/scss/custom/_functions";
@import "@/assets/scss/custom/_variables";
@import "~bootstrap/scss/variables";

.card-box {
    flex-grow: 1;
    cursor: pointer;
    margin: 5px;
    padding: 10px;
    width: 298px;
    max-width: 310px;
    border-radius: 5px;
    background-color: $card-bg;
    background-clip: border-box;
    border: $card-border-width solid $card-border-color;
}

.card-box:hover {
  background-color: $gray-200;
}
</style>
