import Vue from 'vue'
import { createStore } from 'vuex'
import { UserProfile } from  "@/modules/common/domain"
import { Organisation } from  "@/modules/products/domain"
import { IUserProfile, IFetchable,IUserLocalStorage } from '@/modules/common/domain/types';
import { IOrganisation } from '@/modules/products/domain/types';
import { workflow, governanceWorkflow } from '@/vuex';
import { loadOrgIdFromLocalStorage } from './localStorage'

class State {
    // Sign in params
    public showSecure: boolean = false
    public showSignIn: boolean = true
    public postLoginRoute: string = '/landing'
    public $auth: any
    public apiBase: string = ''
    public toastText: string = "";
    public toastVisible: boolean = false;
    public currentUser: IUserProfile & IFetchable<IUserProfile> = new UserProfile()
    public organisation: IOrganisation & IFetchable<IOrganisation> = new Organisation()
    public feeLabels: string[] = [];
    public clipboardData: any;

    public savedPortfolioFields: any;

    // Possible list of stack trace route
    //   Individual one is used to know if going back and forth between prod and portfolio to keep settings
    public previousRoute: any;
    public previousRoutes: any[] = [];
    public backButtonRoute = false;
}

const store = createStore({
    state: new State(),

    mutations: {
        logout(state:State) {
            state.currentUser = new UserProfile();
            state.organisation = new Organisation();
        },
        loginStarted(state:State) {
        },
        loginComplete(state:State) {
            state.currentUser = new UserProfile();
            state.organisation = new Organisation();
            if (state.$auth.isAuthenticated) {
                state.currentUser.fetch().then(() => {
                    if (state.currentUser.organisationIds && state.currentUser.organisationIds.length === 1) {
                        state.organisation.fetch(state.currentUser.organisationIds[0]);
                    }
                    try {
                        let lastSession: IUserLocalStorage = loadOrgIdFromLocalStorage()
                        if (lastSession && lastSession.lastOrgId) {
                            if (lastSession.userId == state.$auth.user.userId) {
                                if (state.currentUser.organisationIds.includes(lastSession.lastOrgId)) {

                                    state.organisation.fetch(lastSession.lastOrgId);
                                }
                                // Need to think about the possibility of should it be able to store multiple sessions? Or is that too much?
                            }

                        }
                    } catch (error) {
                        // console.error('Error loading last accessed organisation local storage:', error);
                        console.error("Unable to get last accessed orgId: ", error)
                    }
                })
            }
        },
        savePortfolioFields(state:State, inputFields) {
            state.savedPortfolioFields = inputFields
        }
    },
    actions: {
    },
    modules: {
        workflow,
        governanceWorkflow
    }
})

export default store
