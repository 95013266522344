export class FetchBase {
  public fetchTime: Date = new Date();
  public isFetched: boolean = false;
  public isFetching: boolean = false;
  public isError: boolean = false;
  public errorObject: any = {};

  protected startFetch() {
      this.isFetching = true;
      this.isFetched = false;
      this.isError = false;
      this.errorObject = {};
  }

  protected endFetch(success: boolean, error: any) {
      this.isFetching = false;
      this.isFetched = success;
      this.fetchTime = new Date();
      this.isError = Object.keys(error).length > 0;
      this.errorObject = error;
  }
}

export function fetchable(
  target: any,
  propertyName: string,
  propertyDesciptor: PropertyDescriptor): PropertyDescriptor {
  const method = propertyDesciptor.value;

  propertyDesciptor.value = function(...args: any[]) {
      target.startFetch.apply(this);

      return method.apply(this, args).then((result: any) => {
        target.endFetch.apply(this, [true, {}]);
      }, (err: any) => {
        target.endFetch.apply(this, [false, err.response]);
      });
  }
  return propertyDesciptor;
};
