import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-135c5838"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fa fa-warning local-warning" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createElementBlock("i", _hoisted_1, null, 512)), [
    [_directive_tooltip, _ctx.getTitle(_ctx.toolTipTitle)]
  ])
}