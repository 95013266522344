export enum GovernanceWorkflowUpdateTypes {
    COMMENT = 'COMMENT',
    CANCEL = 'CANCEL',
    SUBMIT = 'SUBMIT',
    UNSUBMIT = 'UNSUBMIT',
    REJECT = 'REJECT',
    APPROVE = 'APPROVE',
    IN_PROGRESS = 'IN_PROGRESS',
    START = 'START',
    CLOSE = 'CLOSE'
}
