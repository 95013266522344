<template>
    <div class="spinner-outer">
      <div :class="['spinner-border', 'text-primary', small && `spinner-border-sm`, `text-${variant}`]" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    variant: {
      type: String,
      default : 'primary'
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  setup() {
  }
})
</script>

<style scoped lang="scss">
.spinner-outer {
  display: block;
  background-color: transparent;
  width: 100%;
  height: 100%;
}

.spinner {
  height: 100%;
  width: auto;
  object-fit: scale-down;
}
</style>
