import { FetchBase, fetchable } from '@/modules/common/domain'
import TodoService from '@/modules/products/services/TodoService';
import { ITodo, ITodoItem } from '@/modules/products/domain/types';
import { IFetchable } from '@/modules/common/domain/types/IFetchable';

export class Todo extends FetchBase implements IFetchable<ITodo>, ITodo {
    public items: ITodoItem[] = []

    private todoService = new TodoService();

    @fetchable
    public async fetch(orgID: string) {
        this.items = await this.todoService.getTodoItems(orgID);
    }
}
