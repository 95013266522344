<template>
        <i class="fa fa-warning local-warning" v-tooltip="getTitle(toolTipTitle)"/>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import store from "@/store";

export default defineComponent({
    props: {
        toolTipTitle: {
            type: String,
            default: ""
        },
        customTitle: {
            type: Boolean,
            default: false
        },
        appendedTitle: {
            type: String,
            default: ""
        }
    },
    setup(props) {

        function getTitle(title: string) {
            let text = ''
            if (props.customTitle) {
                return props.toolTipTitle;
            } else if (title === 'mandatoryField') {
                return 'Required field.';
            } else if (title === 'invalidURL') {
                return 'Please enter a valid URL.';
            } else if (title === 'invalidInput') {
                text = 'Please enter a valid input. Example of accepted format - ';
            } else if (title === 'invalidSelectInput') {
                return 'Please select a valid input.'
            } else if(title === 'invalidComparison') {
                text = 'Please enter a valid value. ';
            } else if (title === 'uniqueInput') {
                return 'The value must be unique.'
            } else {
                text = '';
            }

            if (props.appendedTitle !== '') {
                text += props.appendedTitle
            }
            return text;
        }

        return {
            getTitle
        }
    }
})
</script>
<style scoped lang="scss">
@import "~bootstrap/scss/functions";
@import "@/assets/scss/custom/_functions";
@import "@/assets/scss/custom/_variables";
@import "~bootstrap/scss/variables";

.local-warning {
    margin: 0;
    padding: 0;
    max-width: 16px;
    min-width: 16px;
    color: red;
}
</style>
