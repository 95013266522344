import { IAttachment } from '@/modules/products/domain/types'

// To parse this data:
//
//   import { Convert, Product } from "./file";
//
//   const product = Convert.toProduct(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

export interface Product {
    additionalInformation?: AdditionalInformation;
    applicationUri?: string;
    brand: string;
    brandName?: string;
    cardArt?: CardArt[];
    features?: Features[];
    eligibility?: Eligibility[];
    bundles?: Bundle[];
    description: string;
    effectiveFrom?: Date;
    effectiveTo?: Date;
    isTailored: boolean;
    lastUpdated: Date;
    name: string;
    productCategory: ProductCategory;
    productId: string;
    fees?: Fees[];
    lendingRates?: LendingRates[];
    depositRates?: DepositRates[];
    constraints?: Constraints[];
    'x-pc-offMarket'?: boolean;
    'x-pc-nonCDR'?: boolean;
    'x-pc-attachments'?: IAttachment[];
}

export interface AdditionalInformation {
    bundleUri?: string;
    eligibilityUri?: string;
    feesAndPricingUri?: string;
    overviewUri?: string;
    termsUri?: string;
    additionalOverviewUris?: AdditionalUriDetails[];
    additionalTermsUris?: AdditionalUriDetails[];
    additionalEligibilityUris?: AdditionalUriDetails[];
    additionalFeesAndPricingUris?: AdditionalUriDetails[];
    additionalBundleUris?: AdditionalUriDetails[];
    'x-pc-customTags'?: { [ index: string ]: any };
}

export interface AdditionalUriDetails {
    id?: string;
    description?: string;
    additionalInfoUri: string;
}

export interface Features {
    featureType: string;
    additionalValue?: string;
    additionalInfo?: string;
    additionalInfoUri?: string;
    'x-pc-customTags'?: { [ index: string ]: any };
}

export interface Eligibility {
    eligibilityType: string;
    additionalValue?: string;
    additionalInfo?: string;
    additionalInfoUri?: string;
    'x-pc-customTags'?: { [ index: string ]: any };
}

export interface LendingRates {
    additionalInfo?: string;
    additionalInfoUri?: string;
    comparisonRate?: string;
    additionalValue?: string;
    repaymentType?: string;
    loanPurpose?: string;
    applicationFrequency?: string;
    calculationFrequency?: string;
    interestPaymentDue?: string;
    lendingRateType?: string;
    rate?: string;
    tiers?: LendingRateTiers[];
    'x-pc-rateId'?: string;
    'x-pc-customTags'?: { [ index: string ]: any };
}

export interface LendingRateTiers {
    maximumValue?: string;
    minimumValue?: string;
    name?: string;
    unitOfMeasure?: string;
    rateApplicationMethod?: string;
    'x-pc-customTags'?: { [ index: string ]: any };
}

export interface DepositRates {
    additionalInfo?: string;
    additionalInfoUri?: string;
    additionalValue?: string;
    applicationFrequency?: string;
    calculationFrequency?: string;
    interestPaymentDue?: string;
    depositRateType?: string;
    rate?: string;
    tiers?: DepositRateTiers[];
    'x-pc-customTags'?: { [ index: string ]: any };
}

export interface DepositRateTiers {
    maximumValue?: string;
    minimumValue?: string;
    name?: string;
    unitOfMeasure?: string;
    rateApplicationMethod?: string;
    'x-pc-customTags'?: { [ index: string ]: any };
}

export interface Fees {
    additionalValue?: string;
    amount?: string;
    currency?: string;
    feeType?: string;
    name?: string;
    balanceRate?: string;
    transactionRate?: string;
    accruedRate?: string;
    accrualFrequency?: string;
    additionalInfoUri?: string;
    additionalInfo?: string;
    discounts?: Discounts[]
    'x-pc-customTags'?: { [ index: string ]: any };
}

export interface Constraints {
    additionalValue?: string;
    constraintType?: string;
    'x-pc-customTags'?: { [ index: string ]: any };
}

export interface Discounts {
    additionalInfo?: string;
    additionalInfoUri: string;
    amount?: string;
    description?: string;
    discountType?: string;
    eligibility?: DiscountEligibility[]
    'x-pc-customTags'?: { [ index: string ]: any };
}

export interface DiscountEligibility {
    additionalInfo?: string;
    discountEligibilityType?: string;
    additionalValue?: string;
    additionalInfoUri?: string;
    'x-pc-customTags'?: { [ index: string ]: any };
}

export interface Bundle {
    name: string
    description: string
    additionalInfo?: string
    additionalInfoUri?: string
    productIds?: string[]
    'x-pc-customTags'?: { [ index: string ]: any };
}

export interface CardArt {
    imageUri: string;
    title?: string;
    'x-pc-customTags'?: { [ index: string ]: any };
}

export enum ProductCategory {
    BusinessLoans = 'BUSINESS_LOANS',
    CredAndChrgCards = 'CRED_AND_CHRG_CARDS',
    Leases = 'LEASES',
    MarginLoans = 'MARGIN_LOANS',
    Overdrafts = 'OVERDRAFTS',
    PersLoans = 'PERS_LOANS',
    RegulatedTrustAccounts = 'REGULATED_TRUST_ACCOUNTS',
    ResidentialMortgages = 'RESIDENTIAL_MORTGAGES',
    TermDeposits = 'TERM_DEPOSITS',
    TradeFinance = 'TRADE_FINANCE',
    TransAndSavingsAccounts = 'TRANS_AND_SAVINGS_ACCOUNTS',
    TravelCards = 'TRAVEL_CARDS',
}

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class Convert {
    public static toProduct(json: string): Product {
        return cast(JSON.parse(json), r('Product'))
    }

    public static productToJson(value: Product): string {
        return JSON.stringify(uncast(value, r('Product')), null, 2)
    }
}

function invalidValue(typ: any, val: any): never {
    throw Error(`Invalid value ${JSON.stringify(val)} for type ${JSON.stringify(typ)}`)
}

function jsonToJSProps(typ: any): any {
    if (typ.jsonToJS === undefined) {
        var map: any = {}
        typ.props.forEach((p: any) => (map[p.json] = { key: p.js, typ: p.typ }))
        typ.jsonToJS = map
    }
    return typ.jsonToJS
}

function jsToJSONProps(typ: any): any {
    if (typ.jsToJSON === undefined) {
        var map: any = {}
        typ.props.forEach((p: any) => (map[p.js] = { key: p.json, typ: p.typ }))
        typ.jsToJSON = map
    }
    return typ.jsToJSON
}

function transform(val: any, typ: any, getProps: any): any {
    function transformPrimitive(typ: string, val: any): any {
        if (typeof typ === typeof val) return val
        return invalidValue(typ, val)
    }

    function transformUnion(typs: any[], val: any): any {
    // val must validate against one typ in typs
        var l = typs.length
        for (var i = 0; i < l; i++) {
            var typ = typs[i]
            try {
                return transform(val, typ, getProps)
            } catch (_) { }
        }
        return invalidValue(typs, val)
    }

    function transformEnum(cases: string[], val: any): any {
        if (cases.indexOf(val) !== -1) return val
        return invalidValue(cases, val)
    }

    function transformArray(typ: any, val: any): any {
    // val must be an array with no invalid elements
        if (!Array.isArray(val)) return invalidValue('array', val)
        return val.map(el => transform(el, typ, getProps))
    }

    function transformDate(typ: any, val: any): any {
        if (val === null) {
            return null
        }
        const d = new Date(val)
        if (isNaN(d.valueOf())) {
            return invalidValue('Date', val)
        }
        return d
    }

    function transformObject(props: { [k: string]: any }, additional: any, val: any): any {
        if (val === null || typeof val !== 'object' || Array.isArray(val)) {
            return invalidValue('object', val)
        }
        var result: any = {}
        Object.getOwnPropertyNames(props).forEach(key => {
            const prop = props[key]
            const v = Object.prototype.hasOwnProperty.call(val, key) ? val[key] : undefined
            result[prop.key] = transform(v, prop.typ, getProps)
        })
        Object.getOwnPropertyNames(val).forEach(key => {
            if (!Object.prototype.hasOwnProperty.call(props, key)) {
                result[key] = transform(val[key], additional, getProps)
            }
        })
        return result
    }

    if (typ === 'any') return val
    if (typ === null) {
        if (val === null) return val
        return invalidValue(typ, val)
    }
    if (typ === false) return invalidValue(typ, val)
    while (typeof typ === 'object' && typ.ref !== undefined) {
        typ = typeMap[typ.ref]
    }
    if (Array.isArray(typ)) return transformEnum(typ, val)
    if (typeof typ === 'object') {
        return typ.hasOwnProperty('unionMembers') ? transformUnion(typ.unionMembers, val)
            : typ.hasOwnProperty('arrayItems') ? transformArray(typ.arrayItems, val)
                : typ.hasOwnProperty('props') ? transformObject(getProps(typ), typ.additional, val)
                    : invalidValue(typ, val)
    }
    // Numbers can be parsed by Date but shouldn't be.
    if (typ === Date && typeof val !== 'number') return transformDate(typ, val)
    return transformPrimitive(typ, val)
}

function cast<T>(val: any, typ: any): T {
    return transform(val, typ, jsonToJSProps)
}

function uncast<T>(val: T, typ: any): any {
    return transform(val, typ, jsToJSONProps)
}

function a(typ: any) {
    return { arrayItems: typ }
}

function u(...typs: any[]) {
    return { unionMembers: typs }
}

function o(props: any[], additional: any) {
    return { props, additional }
}

function m(additional: any) {
    return { props: [], additional }
}

function r(name: string) {
    return { ref: name }
}

const typeMap: any = {
    'Product': o([
        { json: 'additionalInformation', js: 'additionalInformation', typ: u(undefined, r('AdditionalInformation')) },
        { json: 'applicationUri', js: 'applicationUri', typ: u(undefined, '') },
        { json: 'brand', js: 'brand', typ: '' },
        { json: 'brandName', js: 'brandName', typ: u(undefined, '') },
        { json: 'cardArt', js: 'cardArt', typ: u(undefined, a(r('CardArt'))) },
        { json: 'description', js: 'description', typ: '' },
        { json: 'effectiveFrom', js: 'effectiveFrom', typ: u(undefined, Date) },
        { json: 'effectiveTo', js: 'effectiveTo', typ: u(undefined, Date) },
        { json: 'isTailored', js: 'isTailored', typ: true },
        { json: 'lastUpdated', js: 'lastUpdated', typ: Date },
        { json: 'name', js: 'name', typ: '' },
        { json: 'productCategory', js: 'productCategory', typ: r('ProductCategory') },
        { json: 'productId', js: 'productId', typ: '' }
    ], 'any'),
    'AdditionalInformation': o([
        { json: 'bundleUri', js: 'bundleUri', typ: u(undefined, '') },
        { json: 'eligibilityUri', js: 'eligibilityUri', typ: u(undefined, '') },
        { json: 'feesAndPricingUri', js: 'feesAndPricingUri', typ: u(undefined, '') },
        { json: 'overviewUri', js: 'overviewUri', typ: u(undefined, '') },
        { json: 'termsUri', js: 'termsUri', typ: u(undefined, '') }
    ], 'any'),
    'CardArt': o([
        { json: 'imageUri', js: 'imageUri', typ: '' },
        { json: 'title', js: 'title', typ: u(undefined, '') }
    ], 'any'),
    'ProductCategory': [
        'BUSINESS_LOANS',
        'CRED_AND_CHRG_CARDS',
        'LEASES',
        'MARGIN_LOANS',
        'OVERDRAFTS',
        'PERS_LOANS',
        'REGULATED_TRUST_ACCOUNTS',
        'RESIDENTIAL_MORTGAGES',
        'TERM_DEPOSITS',
        'TRADE_FINANCE',
        'TRANS_AND_SAVINGS_ACCOUNTS',
        'TRAVEL_CARDS'
    ]
}
