<template>
  <div  :class="['dropdown-container',{'btn-group': split},directionClass, parentClass]">
    <template v-if="title">
      <template v-if="split">
        <button :class="['w-100', 'btn',`btn-${btnVariant}`, `btn-${btnSize}`]" type="button">
          {{ title }}
        </button>
        <button type="button" 
          :class="['btn',
            `btn-${btnVariant}`,
            'dropdown-toggle',
            `btn-${btnSize}`,
            'dropdown-toggle-split'
          ]"
          data-bs-toggle="dropdown"
          id="dropdownMenuButton"
          :data-bs-auto-close=autoClose
          :data-bs-display="display"
          :disabled="disabled"
          >
          <span class="visually-hidden">Toggle Dropdown</span>
        </button>
      </template>
      <button v-else type="button" 
        :class="['btn',  
          'w-100',
          `btn-${btnVariant}`,
          'dropdown-toggle',
          `btn-${btnSize}`,
          btnClass
        ]"
        data-bs-toggle="dropdown"
        id="dropdownMenuButton"
        :data-bs-auto-close=autoClose
        :data-bs-display="display"
        :disabled="disabled"
        >
       {{ title }}
      </button>

    </template>
    <div v-else class="w-100 dropdown-toggle dropdown-toggle-no-chevron"
      data-bs-toggle="dropdown"
      id="dropdownMenuButton"
      :data-bs-auto-close=autoClose>
      <slot name="title"></slot>
    </div>
    <ul ref="dropdownMenuRef" :class="['dropdown-menu', menuAlignment]" aria-labelledby="dropdownMenuButton">
      <li v-for="(option, index) in options" :key="index">
        <a href="#" :class="['dropdown-item', {'disabled': option.disabled}]" @click="selectOption($event, option)">{{ option.text }}</a>
      </li>
      <slot v-if="!options"></slot>
    </ul>
</div>
</template>
<script lang="ts">
import { Dropdown } from 'bootstrap';
import { defineComponent, computed, ref } from 'vue';
export default defineComponent({
  name: 'base-dropdown',
  props: {
      direction: {
        type: String,
        validator: (value: string) => ['up', 'down', 'start', 'end'].includes(value),
      },
      title: {
        type: String,
        description: 'Title of dropdown'
      },
      options: {
        type: Array,
        default: null,
      },
      btnVariant: {
        type: String,
        default: 'secondary',
      },
      btnSize: {
        type: String,
        default: 'sm'
      },
      parentClass: {
        type: String
      },
      autoClose: {
        default: true
      },
      menuAlignment: String,
      split: {
        type: Boolean,
        default: false
      },
      display: {
        type: String
      },
      btnClass: String,
      disabled: Boolean
  },
  setup(props, {emit}) {
    const dropdownMenuRef = ref();

    const selectOption = (event:any ,option: any) => {
      event.preventDefault()
      emit('select', option)
    };

    const directionClass = computed(() => {
      return `drop${props.direction ? props.direction : 'down'}`
    });

    const hide = () => {
      if (dropdownMenuRef.value) {
        new Dropdown(dropdownMenuRef.value).hide();
      }
    };

    return {
      selectOption,
      directionClass,
      dropdownMenuRef,
      hide
    }
  }
  
})
</script>
<style>
.dropdown {
  list-style-type: none;
}

.dropdown .dropdown-toggle {
  cursor: pointer;
}

.dropdown-container {
  width: 100%;
}
.dropdown-toggle-no-chevron::after {
  display: none !important;
}

.dropdown-menu-scroll {
  height:  450px;
  overflow-y: scroll;
}
</style>
