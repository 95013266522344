<template>
    <footer class="footer has-cards">
        <div class="container">
            <hr>
            <div class="row align-items-center justify-content-md-between">
                <div class="col-md-6">
                    <div class="copyright">
                        &copy; {{year}}
                        <a href="https://www.redcrew.com.au" target="_blank" rel="noopener">RedCrew</a>
                    </div>
                </div>
                <div class="col-md-6 text-end my-0 py-0">
                  <router-link to="/">
                      <img src="/img/brand/pc-light-flat.png" alt="logo" height="20px">
                  </router-link>
                </div>
            </div>
        </div>
    </footer>
</template>
<script lang="js">
export default {
    name: 'app-footer',
    data() {
        return {
            year: new Date().getFullYear()
        }
    }
}
</script>
<style>
</style>
