export const hotjar = {
    hotjar: () => {
        const hjId = process.env.VUE_APP_HOTJAR_TRACKING_ID

        console.log('Hotjar Tracking Start');
        (function(h: any, o, t, j, a: any = {}, r: any = {}) {
            h.hj = h.hj || function() { (h.hj.q = h.hj.q || []).push(arguments) };
            h._hjSettings = { hjid: hjId, hjsv: 6 };
            a = o.getElementsByTagName('head')[0];
            r = o.createElement('script'); r.async = 1;
            r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
            a.appendChild(r);
        })(window, document, 'https://static.hotjar.com/c/hotjar-','.js?sv=');
    }
}