export enum IProductCategory {
    TRANS_AND_SAVINGS_ACCOUNTS = 'TRANS_AND_SAVINGS_ACCOUNTS',
    TERM_DEPOSITS = 'TERM_DEPOSITS',
    TRAVEL_CARDS = 'TRAVEL_CARDS',
    REGULATED_TRUST_ACCOUNTS = 'REGULATED_TRUST_ACCOUNTS',
    RESIDENTIAL_MORTGAGES = 'RESIDENTIAL_MORTGAGES',
    CRED_AND_CHRG_CARDS = 'CRED_AND_CHRG_CARDS',
    PERS_LOANS = 'PERS_LOANS',
    MARGIN_LOANS = 'MARGIN_LOANS',
    LEASES = 'LEASES',
    TRADE_FINANCE = 'TRADE_FINANCE',
    OVERDRAFTS = 'OVERDRAFTS',
    BUSINESS_LOANS = 'BUSINESS_LOANS'
}
