import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-41ee8610"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_VueDatePicker, {
      modelValue: _ctx.inputValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
      placeholder: _ctx.placeholder,
      "auto-position": _ctx.autoPosition,
      "auto-apply": _ctx.autoApply,
      "enable-time-picker": false,
      "calendar-cell-class-name": _ctx.calendarCellClassName,
      "input-class-name": _ctx.inputClassName,
      "menu-class-name": _ctx.menuClassName,
      "time-picker": _ctx.timePicker,
      clearable: false,
      "max-date": _ctx.max,
      "min-date": _ctx.min,
      state: _ctx.state,
      format: _ctx.format,
      onClosed: _ctx.hidden,
      disabled: _ctx.disabled,
      locale: _ctx.locale
    }, null, 8, ["modelValue", "placeholder", "auto-position", "auto-apply", "calendar-cell-class-name", "input-class-name", "menu-class-name", "time-picker", "max-date", "min-date", "state", "format", "onClosed", "disabled", "locale"])
  ]))
}