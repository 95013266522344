import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d0fad68"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "value", "checked", "disabled"]
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['form-check', _ctx.className, {'form-switch': _ctx.switchCheckbox}])
  }, [
    _createElementVNode("input", {
      class: "form-check-input",
      type: "checkbox",
      id: _ctx.id,
      value: _ctx.value,
      checked: _ctx.isChecked,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args))),
      disabled: _ctx.disable
    }, null, 40, _hoisted_1),
    _createElementVNode("label", {
      class: "form-check-label",
      for: _ctx.id
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 8, _hoisted_2)
  ], 2))
}