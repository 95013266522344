import { IModule } from '@/framework/domain/types/IModule';
import { BankingProductsModule } from './products/module';
import { EnergyProductsModule } from './energy/module';
import { RiskModule } from './risk/module';
import { SupplierModule } from './supplier/module';
import { RefDataModule } from './refdata/module';
import { FarModule } from './far/module';
import { AdminModule } from './admin/module';

export interface ModuleMenu {
    title: string,
    description: string,
    landingPath: string
}

const Modules: IModule[] = [
    new BankingProductsModule(),
    new EnergyProductsModule(),
    new RiskModule(),
    new SupplierModule(),
    new RefDataModule(),
    new FarModule(),
    new AdminModule(),
]

export class ModuleRegister {

    private route: string;

    constructor(route: string) {
        this.route = route;
    }

    public get modules(): IModule[] {
        return Modules;
    }

    public get showModuleMenu(): boolean {
        if (this.moduleMenuItems.length > 0) return true; 
        return false;
    }

    public get currentModule(): string {
        for (const module of this.modules) {
            if (module.isCurrent(this.route)) return module.name;
        }
        return 'unknown';
    }

    public get moduleMenuItems(): ModuleMenu[] {
        const ret: ModuleMenu[] = [];

        const currentModule = this.currentModule;

        for (const module of this.modules) {
            if (currentModule !== module.name && module.isModuleEnabledForUser()) {
                ret.push({
                    title: module.title,
                    description: module.description,
                    landingPath: module.landingRoute
                });
            }
        }

        return ret;
    }
}


