<template>
    <header class="header-global">
        <base-nav class="navbar-main fixed-top py-0" type="primary" effect="dark" expand>
            <template #brand>
                <router-link class="navbar-brand me-2" to="/">
                    <img src="/img/brand/pc-dark-flat.png" alt="logo">
                </router-link>
            </template>

            <template #content-header="contentProps">
                <div class="row">
                    <div class="col-6 collapse-brand">
                        <router-link to="/">
                            <img src="/img/brand/pc-dark-flat.png">
                        </router-link>
                    </div>
                    <div class="col-6 collapse-close">
                        <close-button @click="contentProps.closeMenu"></close-button>
                    </div>
                </div>
            </template>

            <ul v-if="!showSecure" class="navbar-nav align-items-lg-center ms-lg-auto">
                <a href="/enquire" class="nav-link" role="button">
                    <i class="ni ni-email-83 d-lg-none"></i>
                    <span class="nav-link-inner--text">Enquire</span>
                </a>
                <base-dropdown class="nav-item" menu-classes="dropdown-menu-xl">
                    <template #title>
                        <a href="#" class="nav-link" data-toggle="dropdown" role="button">
                            <i class="ni ni-atom d-lg-none"></i>
                            <span class="nav-link-inner--text">Learn More</span>
                        </a>
                    </template>
                    
                    <div class="dropdown-menu-inner">
                        <router-link to="/compliance" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="ni ni-check-bold"></i>
                            </div>
                            <div class="media-body ms-3">
                                <h6 class="heading text-primary mb-md-1">Compliance</h6>
                                <p class="description d-none d-md-inline-block mb-0">Easily achieve compliance with the Consumer Data Right and other obligations.</p>
                            </div>
                        </router-link>
                        <router-link to="/insight" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-success rounded-circle text-white">
                                <i class="ni ni-compass-04"></i>
                            </div>
                            <div class="media-body ms-3">
                                <h5 class="heading text-success mb-md-1">Market Insight</h5>
                                <p class="description d-none d-md-inline-block mb-0">Compare your products to other market offerings to understand how your portfolio will perform.</p>
                            </div>
                        </router-link>
                        <router-link to="/portfolio" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-danger rounded-circle text-white">
                                <i class="ni ni-like-2"></i>
                            </div>
                            <div class="media-body ms-3">
                                <h5 class="heading text-danger mb-md-1">Portfolio Management</h5>
                                <p class="description d-none d-md-inline-block mb-0">Manage changes to your product information with approval workflows and reporting for key stakeholders.</p>
                            </div>
                        </router-link>
                    </div>
                </base-dropdown>

                <li v-if="showSignIn" class="nav-item d-lg-block ms-lg-4">
                    <router-link v-if="isLoggedIn" to="/landing" class="btn btn-neutral btn-Enter btn-icon">
                        <span class="btn-inner--icon"><i class="fa fa-sign-in me-2"></i></span>
                        <span class="nav-link-inner--text">Enter</span>
                    </router-link>
                    <router-link v-else to="/login" class="btn btn-neutral btn-Enter btn-icon">
                        <span class="btn-inner--icon"><i class="fa fa-sign-in me-2"></i></span>
                        <span class="nav-link-inner--text">Sign In</span>
                    </router-link>
                </li>
            </ul>

            <ul v-if="showSecure" class="navbar-nav align-items-lg-center ms-lg-auto">
                <slot name="module-navbar"></slot>

                <base-dropdown v-if="showSecure" class="nav-item" menu-classes="dropdown-menu-xl dropdown-menu-right">
                    <template #title>
                        <a class="nav-link" data-toggle="dropdown" role="button">
                            <span class="nav-link-inner--text">Help</span>
                        </a>
                    </template>

                    <div class="dropdown-menu-inner">
                        <a href="https://productcloud.zendesk.com/hc" target="_blank" class="media d-flex align-items-center">
                            <div class="media-body">
                                <h6 class="heading text-primary mb-md-1">Support Portal</h6>
                                <p class="description d-none d-md-inline-block mb-0">Open the ProductCloud Support Portal in a new browser window.</p>
                            </div>
                        </a>
                    </div>
                </base-dropdown>

                <base-dropdown v-if="showSecure && moduleRegister.showModuleMenu && false" class="nav-item" menu-classes="dropdown-menu-xl dropdown-menu-right">
                    <template #title>
                        <a class="nav-link" data-toggle="dropdown" role="button">
                            <span class="nav-link-inner--text"><AppstoreOutlined class="module-store"/></span>
                        </a>
                    </template>

                    <div class="dropdown-menu-inner">
                        <div>
                            <router-link v-for="menuItem in moduleRegister.moduleMenuItems" :to="menuItem.landingPath" class="media d-flex align-items-center" v-bind:key="menuItem.title">
                                <div class="media-body">
                                    <h6 class="heading text-primary mb-md-1">{{ menuItem.title }}</h6>
                                    <p class="description d-none d-md-inline-block mb-0"> {{ menuItem.description }}</p>
                                </div>
                            </router-link>
                        </div>

                    </div>
                </base-dropdown>

                <li class="nav-item d-lg-block ms-lg-2">
                    <base-dropdown menu-classes="dropdown-menu-right profile-dropdown">
                        <template #title>
                            <a class="nav-link px-0" data-toggle="dropdown" role="button">
                                <span class="nav-link-inner--text profile-icon" :style="organisationStyle">
                                    {{ userInitials }}
                                </span>
                            </a>
                        </template>
                        <div class="dropdown-menu-inner">
                            <div v-if="!insightUser">
                                <div class="media">
                                    <b class="text-primary">Switch to</b>
                                </div>
                                <div v-if="isLoading">
                                <spinner class="d-flex justify-content-center"/>
                                </div>
                                <div v-else class="header-org-container">
                                    <div v-for="orgId in user.organisationIds" v-bind:key="orgId">
                                    <header-org
                                        class="my-2"
                                        :organisationId="orgId"
                                        @select="orgSelected"
                                    />
                                </div>
                            </div>
                            <hr>
                            </div>
                            <div class="px-2">
                            <router-link to="/profile/general" class="profile-option-container media d-flex align-items-center mx-1">
                                <SettingOutlined class="me-4 profile-option-icon"/>
                                    <h6 class="heading text-primary">General</h6>
                                </router-link>
                            <router-link to="/profile/security" class="profile-option-container media d-flex align-items-center mx-1">
                                <LockOutlined class="me-4 profile-option-icon"/>
                                    <h6 class="heading text-primary">Security</h6>
                                </router-link>
                            <router-link to="/profile/role" class="profile-option-container media d-flex align-items-center mx-1">
                                <UserOutlined class="me-4 profile-option-icon"/>
                                    <h6 class="heading text-primary">Access Roles</h6>
                                </router-link>
                            <router-link to="/profile/log" class="profile-option-container media d-flex align-items-center mx-1">
                                <ProfileOutlined class="me-4 profile-option-icon"/>
                                    <h6 class="heading text-primary">Activity Log</h6>
                                </router-link>
                            <router-link to="/profile/email" class="profile-option-container media d-flex align-items-center mx-1">
                                <MailOutlined class="me-4 profile-option-icon"/>
                                    <h6 class="heading text-primary">Email Preferences</h6>
                                </router-link>
                            <router-link to="/logout" class="profile-option-container media d-flex align-items-center mx-1">
                                <PoweroffOutlined class="me-4 profile-option-icon"/>
                                    <h6 class="heading text-primary"><b>Sign Out</b></h6>
                                </router-link>
                            </div>
                            <hr>
                            <div class="version-container">
                                <div class="profile-option-container media d-flex align-items-center my-0">
                                    <h6 class="heading app-version">v{{ appVersion }}</h6>
                                </div>
                                <div>
                                <router-link to="/release-notes" class="profile-option-container media d-flex align-items-center">
                                        <h6 class="heading text-primary">Release Notes</h6>
                                    </router-link>
                                </div>
                            </div>
                            <div>
                                <router-link to="/roadmap" class="profile-option-container media d-flex align-items-center mt-2">
                                    <h6 class="heading text-primary">Roadmap</h6>
                                </router-link>
                            </div>
                        </div>
                    </base-dropdown>
                </li>
            </ul>
        </base-nav>
    </header>
</template>
<script lang="ts">
import { AuthAssert } from '@/auth/assertAuth'
import BaseNav from '@/modules/common/components/argon/BaseNav.vue'
import BaseDropdown from '@/modules/common/components/argon/BaseDropdown.vue'
import CloseButton from '@/modules/common/components/argon/CloseButton.vue'
import { IUserProfile, IFetchable } from '@/modules/common/domain/types'
import { IOrganisation, } from '@/modules/products/domain/types'
import { IUserLocalStorage } from '@/modules/common/domain/types';
import { saveOrgIdToLocalStorage, loadOrgIdFromLocalStorage } from '@/localStorage'
import {
    SettingOutlined,
    LockOutlined,
    UserOutlined,
    ProfileOutlined,
    PoweroffOutlined,
    MailOutlined,
    AppstoreOutlined
} from "@ant-design/icons-vue"
import HeaderOrg from '@/modules/products/components/HeaderOrg.vue'
import Spinner from '@/modules/common/components/common/Spinner.vue'
import { defineComponent, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import store from "@/store";
import { ModuleRegister } from "@/modules/register"

export default defineComponent({
    name: 'app-header',
    components: {
        BaseNav,
        CloseButton,
        BaseDropdown,
        SettingOutlined,
        LockOutlined,
        UserOutlined,
        AppstoreOutlined,
        ProfileOutlined,
        PoweroffOutlined,
        MailOutlined,
        HeaderOrg,
        Spinner
    },
    setup() {

        const authAssert = ref<AuthAssert>(new AuthAssert());
        const appVersion = ref<string | undefined>(process.env.VUE_APP_VERSION);
        const organisationFetching = ref(true);
        const organisations = ref<IOrganisation[]>([]);
        const accordianCheck = ref<boolean>(false)
        const route = useRoute();
        const router = useRouter();
        const moduleRegister = new ModuleRegister(route.path);

        const isLoading = computed((): Boolean => {
            if (user.value && user.value.isFetching) return true
            if (organisation.value && organisation.value.isFetching) return true
            return false
        })

        const user = computed((): IUserProfile & IFetchable<IUserProfile> => {
            return store.state.currentUser
        })

        const organisation = computed((): IOrganisation & IFetchable<IOrganisation> => {
            return store.state.organisation
        })

        const userInitials = computed((): string => {
            if (user.value) {
                return user.value.firstName[0] + user.value.lastName[0];
            } else {
                return '??';
            }
        })

        const showSignIn = computed((): Boolean => {
            return store.state.showSignIn
        })

        const showSecure = computed((): Boolean => {
            return store.state.showSecure
        })

        const isOrgSelected = computed((): Boolean => {
            try {
                if (store.state.organisation.organisationId) return true;
            } catch (_) {}
            return false;
        })

        const showTmd = computed((): Boolean => {
            if (organisation.value.features && organisation.value.features.tmd) return true;
            return false;
        })

        const showPortfolioValidaton = computed((): Boolean => {
            const { edit, admin, support, approve} = authAssert.value
            return edit || admin || support || approve
        })

        const showBulkRateEdit = computed((): Boolean => {
            const { edit, admin, support, approve} = authAssert.value
            return edit || admin || support || approve
        })

        const organisationStyle = computed((): any => {
            if (isOrgSelected.value && !isLoading.value) {
                return {
                    'background-position': 'center',
                    'background-image': `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('/${store.state.organisation.iconUri}')`,
                    'color': 'white'
                };
            } else {
                return {
                    'background-image': 'none',
                    'color': '#3652A3'
                };
            }
        })

        const isLoggedIn = computed((): Boolean => {
            return !store.state.$auth.loading && store.state.$auth.isAuthenticated
        })

        const insightUser = computed((): boolean => {
            return !(user.value.organisationIds.length > 0)
        })

        async function orgSelected(organisationId: string) {
            try {
                if (route.name !== "Landing") {
                    await router.push({
                        name: 'Landing',
                        params: {
                            orgId: organisationId
                        }
                    });
                }
                await organisation.value.fetch(organisationId);

                const idFromStorage : IUserLocalStorage = loadOrgIdFromLocalStorage()
                if (idFromStorage) {
                    if (idFromStorage.lastOrgId != organisationId) {
                        saveOrgIdToLocalStorage(organisationId, store.state.$auth.user.userId)
                    } else if (idFromStorage.userId != store.state.$auth.user.userId) {
                        saveOrgIdToLocalStorage(organisationId, store.state.$auth.user.userId)
                    }
                }
            } catch (_) { }
        }

        function accordianClicked(state: boolean) {
            accordianCheck.value = state
        }

        return {
            showSecure,
            showSignIn,
            isLoggedIn,
            isOrgSelected,
            accordianClicked,
            authAssert,
            showPortfolioValidaton,
            showBulkRateEdit,
            store,
            accordianCheck,
            showTmd,
            userInitials,
            insightUser,
            isLoading,
            orgSelected,
            organisationStyle,
            user,
            appVersion,
            moduleRegister
        }
    }
})

</script>
<style lang="scss">
@import "~bootstrap/scss/functions";
@import "@/assets/scss/custom/_functions";
@import "@/assets/scss/custom/_variables";
// @import "~bootstrap/scss/variables";

.profile-dropdown {
    max-height: 85vh;
    min-width: 300px !important;
    border-radius: 0.3rem;
    overflow-y: auto;
}

.profile-icon {
    background: white;
    border-radius: 50%;
    color: white;
    padding: 12px;
    color: white;
    object-fit: center;
    object-position: center;
    background-size: contain;
    font-weight: bold;
}

.header-org-container {
    max-height: 15vh;
    overflow-y: auto;
}

.profile-option-container {
    font-family: inherit;

    h6 {
        text-transform: none;
        margin-bottom: 0;
    }

    .profile-option-icon {
        font-size: $font-size-lg;
    }
}

.version-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.app-version {
    color: $gray-600;
}

.local-yscroll {
    overflow-y: auto;
}

.product-menu {
    max-height: 620px;
    overflow-y: auto;
}

.collapsible {
    width: 100%;
    margin: 0;
    padding: 0;
}

.collapse-button {
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.collapse-button:focus {
    outline: none
}

.collapsed>.opened,
:not(.collapsed)>.closed {
    display: none;
}

.btn-Enter {
    color: $gray-900 !important;
}

.module-store {
    font-size: 20px;
}
</style>
