import * as Generated from '@/modules/products/domain/generated'
import { FetchBase, fetchable } from '@/modules/common/domain'
import RatesService from '@/modules/products/services/RatesService';
import { IProductCategory, IProductRates, IProductRate } from '@/modules/products/domain/types';
import { IFetchable } from '@/modules/common/domain/types/IFetchable';

export class ProductRates extends FetchBase implements IFetchable<IProductRates>, IProductRates {
    public productId: string = '';
    public organisationId: string = '';
    public name: string = '';
    public fees: string = '';
    public productCategory: IProductCategory = IProductCategory.CRED_AND_CHRG_CARDS;

    public rates: IProductRate[] = [];

    private ratesService = new RatesService();
    @fetchable
    public async fetch(orgID: string) {
        this.rates = await this.ratesService.getProductRates(orgID);
    }
}
