import {IDictionary}from "@/modules/common/domain/types"
import {IOutage}from "@/modules/products/domain/types"

export class Outage implements IOutage {
    public outageId: string | undefined;
    public outageTime: string = "";
    public outageEnd: string = "";
    public duration: string = "";
    public readableDuration: string = "";
    public isPartial: boolean = false;
    public explanation: string = "";

    public errors: IDictionary<string> = {};

    constructor(outageData?: IOutage) {
        if (outageData) {
            this.outageId = outageData.outageId;
            this.outageTime = outageData.outageTime;
            this.outageEnd = outageData.outageEnd;
            this.duration = outageData.duration;
            this.readableDuration = outageData.readableDuration;
            this.isPartial = outageData.isPartial;
            this.explanation = outageData.explanation;
        }
    }

    public get startDate(): string {
        const date: Date = new Date(this.outageTime);
        return this.formatDate(date);
    }

    public set startDate(startDate: string) {
        const outageDate: Date = new Date(this.outageTime || this.formatDate(new Date()));
        const newDate: Date = new Date(startDate);
        outageDate.setDate(newDate.getDate());
        outageDate.setMonth(newDate.getMonth());
        outageDate.setFullYear(newDate.getFullYear());
        this.outageTime = outageDate.toISOString();

        this.setDuration();
    }

    public get startTime(): any {
        const date: Date = new Date(this.outageTime);
        return `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    }

    public set startTime(startTime: any) {
        const newDate: Date = new Date(this.outageTime);
        const [hours, minutes, seconds] = startTime.split(":");

        newDate.setHours(+hours);
        newDate.setMinutes(+minutes);
        newDate.setSeconds(+seconds);

        this.outageTime = newDate.toISOString();

        this.setDuration();
    }

    public get endDate(): string {
        const date: Date = new Date(this.outageEnd);
        return this.formatDate(date);
    }

    public set endDate(endDate: string) {
        const outageDate: Date = new Date(this.outageEnd || this.formatDate(new Date()));
        const newDate: Date = new Date(endDate);
        outageDate.setDate(newDate.getDate());
        outageDate.setMonth(newDate.getMonth());
        outageDate.setFullYear(newDate.getFullYear());
        this.outageEnd = outageDate.toISOString();

        this.setDuration();
    }

    public get endTime(): string {
        const date: Date = new Date(this.outageEnd);
        return `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    }

    public set endTime(endTime: string) {
        const newDate: Date = new Date(this.outageEnd);
        const [hours, minutes, seconds] = endTime.split(":");

        newDate.setHours(+hours);
        newDate.setMinutes(+minutes);
        newDate.setSeconds(+seconds);

        this.outageEnd = newDate.toISOString();

        this.setDuration();
    }

    public validate(): boolean {
        this.errors = {};
        let isValid = true;

        if (this.outageTime === undefined || this.outageTime === "") {
            this.errors["outageTime"] = "Required field";
            isValid = false;
        }

        if (this.outageEnd === undefined || this.outageEnd === "") {
            this.errors["outageEnd"] = "Required field";
            isValid = false;
        }

        if (this.outageEnd && new Date(this.outageEnd) <= new Date()) {
            this.errors["outageEnd"] = "End time must be greater than the current time";
            isValid = false;
        }

        if (this.outageTime && this.outageEnd && (new Date(this.outageTime) >= new Date(this.outageEnd))) {
            this.errors["duration"] = "Start time must be less than the end time";
            isValid = false;
        }

        if (this.explanation === undefined || this.explanation === "") {
            this.errors["explanation"] = "Required field";
            isValid = false;
        }

        return isValid;
    }

    private formatDate(date: Date): string {
        const d: Date = new Date(date);
        const year: number = d.getFullYear();
        let month: string = "" + (d.getMonth() + 1);
        let day: string = "" + d.getDate();

        if (month.length < 2) {
            month = "0" + month;
        }
        if (day.length < 2) {
            day = "0" + day;
        }

        return [year, month, day].join("-");
    }

    private setDuration(): void {
        if (!this.outageTime) { return; }
        if (!this.outageEnd) { return; }

        const timeStart: number = new Date(this.outageTime).getTime();
        const timeEnd: number = new Date(this.outageEnd).getTime();
        const hourDiff: number = timeEnd - timeStart;
        const secDiff: number = hourDiff / 1000;
        const minDiff: number = hourDiff / 60 / 1000;
        const hDiff: number = hourDiff / 3600 / 1000;
        const humanReadable: any = {};
        humanReadable.hours = Math.floor(hDiff);
        humanReadable.minutes = minDiff - 60 * humanReadable.hours;

        this.readableDuration = `${humanReadable.hours} Hours ${humanReadable.minutes} minutes`;
        this.duration = `PT${humanReadable.hours}H${humanReadable.minutes}M`;
    }
}
