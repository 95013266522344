import { FetchBase, fetchable } from './FetchBase'
import UserProfileService from '@/modules/common/services/UserProfileService';
import { IFetchable, IUserBrief, IUsers } from '@/modules/common/domain/types';


export class Users extends FetchBase implements IFetchable<IUsers>, IUsers {
    public role = 'READ';
    public users: IUserBrief[] = []

    private userProfileService = new UserProfileService();

    @fetchable
    public async fetch(organisationId: string, role: string) {
        const users = await this.userProfileService.getUsersByRole(organisationId, role);

        this.role = role;
        this.users = users;
    }
}
