<template>
  <div class="form-check mb-0" @click="clicked">
    <input
      ref="radioInput"
      type="radio"
      class="form-check-input"
      :id="id"
      :name="name"
      :value="value"
      :checked="modelValue === value"
      @change="handleChange(value)"
      :disabled="disabled"
    />
    <label class="form-check-label ms-4" :for="id">
      <slot></slot>
    </label>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: 'form-radio',
  props: {
    id: String,
    name: String,
    value: [String, Number],
    modelValue: [String, Number],
    label: String,
    checked: Boolean,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {emit}) {

    const radioInput = ref()

    const handleChange = (value: string | Number) => {
      emit('update:modelValue',value);
    };

    const clicked = () => {
      if (!props.disabled) {
        radioInput.value.dispatchEvent(new Event('change'));
      }
    }

    return {
      handleChange,
      radioInput,
      clicked
    }
  }
})

</script>

<style scoped lang="scss">

</style>