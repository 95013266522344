import { FetchBase, fetchable } from '@/modules/common/domain'
import OrganisationService from '@/modules/products/services/OrganisationService';
import { IOrganisationSettingsEvent, IOrganisationSettingsEvents } from '@/modules/products/domain/types';
import { IFetchable } from '@/modules/common/domain/types/IFetchable';

export class SettingsEvents extends FetchBase implements IFetchable<IOrganisationSettingsEvents>, IOrganisationSettingsEvents {
    public settingsEvents: IOrganisationSettingsEvent[] = [];

    private eventsService = new OrganisationService();

    @fetchable
    public async fetch(orgID: string) {
        this.settingsEvents = await this.eventsService.getSettingsHistory(orgID);
    }
}