import store from '@/store';
import { RouteRecordRaw, NavigationGuard } from 'vue-router';
import { IModule } from '@/framework/domain/types/IModule';
import AppFooter from "@/framework/components/layout/AppFooter.vue";

export class AdminModule implements IModule {

    public name = 'admin';
    public title = 'PRODUCTCLOUD ADMIN';
    public description = 'Administration of ProductCloud';
    public landingRoute = '/admin/landing';

    public isCurrent(route: string): boolean {
        if (/^\/admin/.test(route)) return true;
        return false;
    }

    public isModuleEnabledForUser(): boolean {
        return true;//XXXX
    }

    public routes(guardSecure: NavigationGuard, guardPublic: NavigationGuard): RouteRecordRaw[] {
        return [
            {
                path: "/admin/landing",
                beforeEnter: guardSecure,
                name: "AdminLanding",
                components: {
                    header: () => import("./components/layout/AppHeader.vue"),
                    default: () => import("./views/secure/Landing.vue"),
                    footer: AppFooter,
                },
            },    
        ]
        
    }
}


