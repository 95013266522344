export enum WorkflowUpdateType {
    COMMENT = 'COMMENT',
    CANCEL = 'CANCEL',
    SUBMIT = 'SUBMIT',
    UNSUBMIT = 'UNSUBMIT',
    REJECT = 'REJECT',
    APPROVE = 'APPROVE',
    PUBLISH = 'PUBLISH',
    UNPUBLISH = 'UNPUBLISH',
    DECOMMISSION = 'DECOMMISSION'
}
