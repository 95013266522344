import { FetchBase, fetchable } from '@/modules/common/domain'
import UserProfileService from '@/modules/common/services/UserProfileService';
import { IUserFull, IUsersAccessRoles } from '@/modules/common/domain/types';
import { IFetchable } from '@/modules/common/domain/types/IFetchable';

export class UsersWithAccessRoles extends FetchBase implements IFetchable<IUsersAccessRoles>, IUsersAccessRoles {
    public users: IUserFull[] = []

    private userProfileService = new UserProfileService();

    @fetchable
    public async fetch(orgID: string) {
        let users = await this.userProfileService.getUsersOfOrganisation(orgID);
        this.users = users.sort((a: IUserFull, b: IUserFull) => {
            if (a.firstName < b.firstName) return -1;
            if (a.firstName > b.firstName) return 1;
            return 0;
        })
    }
}
