import { FetchBase, fetchable } from '@/modules/common/domain'
import { Product } from './Product'
import PortfolioService from '@/modules/products/services/PortfolioService';
import { IPortfolio, IProduct } from '@/modules/products/domain/types';
import { IFetchable } from '@/modules/common/domain/types/IFetchable';

export class Portfolio extends FetchBase implements IFetchable<IPortfolio>, IPortfolio {
    private organisationId = ''
    public productCount = 0
    public publishedCount = 0
    public wipCount = 0
    public workflowCount = 0
    public products: Product[] = []

    private portfolioService = new PortfolioService();

    @fetchable
    public async fetch(orgID: string) {
        const products: IProduct[] = await this.portfolioService.getProducts(orgID);

        this.reset();

        for (let rawprod of products) {
            let newProd: Product = new Product()
            newProd.productId = rawprod.productId
            newProd.productCategory = rawprod.productCategory
            newProd.name = rawprod.name
            newProd.brand = rawprod.brand
            newProd.brandName = (rawprod.brandName) ? rawprod.brandName : ''
            newProd.isWip = rawprod.isWip
            newProd.wipVersion = rawprod.wipVersion
            newProd.publishedVersion = rawprod.publishedVersion
            newProd.isPublished = rawprod.isPublished
            newProd.workflowId = rawprod.workflowId
            newProd.isEffective = rawprod.isEffective
            newProd.lastUpdated = rawprod.lastUpdated
            newProd.xPcOffMarket = rawprod.xPcOffMarket
            newProd.xPcNonCDR = rawprod.xPcNonCDR

            if (rawprod.isWip) this.wipCount++
            if (rawprod.isPublished) this.publishedCount++
            if (rawprod.workflowId) this.workflowCount++

            this.products.push(newProd)
        }
        this.productCount = this.products.length
    }

    private reset() {
        this.productCount = 0
        this.publishedCount = 0
        this.wipCount = 0
        this.workflowCount = 0
        this.products = []
    }
}
