export enum CustomTagType {
    BOOLEAN = 'BOOLEAN',
    STRING = 'STRING',
    IDENTIFIER = 'IDENTIFIER',
    DATETIME = 'DATETIME',
    DATE = 'DATE',
    GROUP = 'GROUP',
    URL = 'URL',
    MEMO = 'MEMO',
    MARKDOWN = 'MARKDOWN'
}
