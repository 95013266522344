import { FetchBase, fetchable } from '@/modules/common/domain'
import ComparisonReportService from '@/modules/products/services/ComparisonReportService';
import { IFetchable} from '@/modules/common/domain/types/IFetchable';
import { ILendingRatesReportFilters , IComparisonReport} from '@/modules/products/domain/types'

export class ComparisonReport extends FetchBase implements IFetchable<any> {
    public report:IComparisonReport[] = [];

    private comparisonReportService = new ComparisonReportService();

    @fetchable
    public async fetch(orgID: string, filters: ILendingRatesReportFilters) {
        this.report = await this.comparisonReportService.getComparisonReports(orgID, filters);
    }
}
