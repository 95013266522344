import * as Axios from 'axios';
import BaseService from '../../../framework/services/BaseService';
import store from '@/store';
import { ProductsValidationCategory } from '@/modules/products/domain/types';

export default class ProductValidationService extends BaseService {
    private apiBase: string;

    constructor() { super(); this.apiBase = store.state.apiBase; }
        
    public async createValidationProcess(orgID: string, categoryType: ProductsValidationCategory[]): Promise<any> {

        const apiConfig: Axios.AxiosRequestConfig = {
        method: "post",
        url: `${this.apiBase}/private/organisations/${orgID}/productsValidation`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            data: { categoryType }
        };

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig);

        return res.data;
    }

    public async getValidationEvent(orgID: string, eventId: any): Promise<any> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: "get",
            url: `${this.apiBase}/private/organisations/${orgID}/productsValidation/${eventId}`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        };

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig);

        return res.data;
    }

    public async getValidationEvents(orgID: string): Promise<any> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/organisations/${orgID}/productsValidation`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig);

        return res.data;
    }
}
