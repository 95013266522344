import { FetchBase, fetchable } from '@/modules/common/domain'
import { IValidationEvents, IValidationEvent } from '@/modules/products/domain/types';
import ProductValidationService from '@/modules/products/services/ProductsValidationService';
import { IFetchable } from '@/modules/common/domain/types/IFetchable';

export class ValidationEvents extends FetchBase implements IFetchable<IValidationEvents>, IValidationEvents {
    public data?: IValidationEvent[];

    private validationService = new ProductValidationService();

    @fetchable
    public async fetch(orgID: string) {
        let data = await this.validationService.getValidationEvents(orgID);
        this.data = data
    }
}