import * as Axios from 'axios';
import BaseService from '../../../framework/services/BaseService';
import store from '@/store';
import { IProductEvent, IEvent, IWorkflow } from '@/modules/products/domain/types';

export default class HistoryService extends BaseService {
    private async getProductEvents(orgID: string, productId: string): Promise<IProductEvent[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/organisations/${orgID}/products/${productId}/events`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IProductEvent[];
    }

    private async getProductWorkflows(orgID: string, productId: string): Promise<IWorkflow[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/organisations/${orgID}/products/${productId}/workflows`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IWorkflow[];
    }

    public async getProductHistory(orgID: string, productId: string): Promise<IEvent[]> {
        let history: IEvent[] = [];

        const productEvents: IProductEvent[] = await this.getProductEvents(orgID, productId);
        const productWorkflows: IWorkflow[] = await this.getProductWorkflows(orgID, productId);

        for (let i = 0; i < productEvents.length; i++) {
            history.push({
                createdAt: productEvents[i].createdAt,
                productEvent: productEvents[i]
            })
        }
        for (let i = 0; i < productWorkflows.length; i++) {
            history.push({
                createdAt: productWorkflows[i].createdAt,
                workflow: productWorkflows[i]
            })
        }

        history.sort((a, b): number => {
            return Date.parse(b.createdAt) - Date.parse(a.createdAt);
        });

        return history;
    }

    public async getProductEvent(orgID: string, productId: string, eventId: string): Promise<IProductEvent> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/organisations/${orgID}/products/${productId}/events/${eventId}`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IProductEvent;
    }
}
