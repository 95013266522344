import { FetchBase, fetchable } from '@/modules/common/domain'
import { IGovernanceWorkflow, IGovernanceWorkflows } from '@/modules/products/domain/types';
import { IFetchable } from '@/modules/common/domain/types/IFetchable';
import GovernanceWorkflowService from '@/modules/products/services/GovernanceWorkflowService'
import { workflow } from '@/vuex';

export class GovernanceWorkflowPortfolio extends FetchBase implements IFetchable<IGovernanceWorkflows>, IGovernanceWorkflows {
    private governanceWorkflowService = new GovernanceWorkflowService();
    public workflows: IGovernanceWorkflow[] = [];
    @fetchable
    public async fetch(orgId: string) {
        const workflows: IGovernanceWorkflow[] = await this.governanceWorkflowService.getAllGovernanceWorkflows(orgId) as IGovernanceWorkflow[];

        this.workflows = workflows;
    }
}
