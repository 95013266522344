<template>
  <div class="time-picker" v-click-outside="closeTimeSelector">
    <div class="selected-time-input-box">
      <input class="selected-time" type="text" v-model="formattedTime" @input="updateTime"
        :class="isValid !== null ? { 'green-border': isValid, 'red-border': !isValid, 'small': sm } : { 'small': sm }"
        :placeholder="placeholder" @click="openTimeSelector" readonly :disabled="disabled" />
      <span class="clock-icon-container">
        <ClockCircleOutlined class="clock-icon" @click="openTimeSelector" />
      </span>
    </div>
    <div class="time-selector-popup"
      :class="{ 'popup-bottom': autoPosition && !popupTop, 'popup-top': autoPosition && popupTop, 'sm-position': sm }"
      v-show="showTimeSelector">
      <input class="time-selector-input" type="text" v-model="formattedTime" @input="updateTime"
      :placeholder="placeholder">
      <div class="picker-box">
        <div class="picker-row">
          <div class="picker-col">
            <button @click="changeHour(1)" class="chevron">
              <UpOutlined />
            </button>
            <input class="time-input-box" type="number" v-model="formattedHours" @input="updateTime" min="01"
              :max="is24 ? 24 : 12" placeholder="--">
            <button @click="changeHour(-1)" class="chevron">
              <DownOutlined />
            </button>
          </div>
          <div class="dots-col">
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
          <div class="picker-col">
            <button @click="changeMinute(1)" class="chevron">
              <UpOutlined />
            </button>
            <input class="time-input-box" type="number" v-model="formattedMinutes" @input="updateTime" min="00" max="59"
              placeholder="--">
            <button @click="changeMinute(-1)" class="chevron">
              <DownOutlined />
            </button>
          </div>
          <div class="picker-col" v-if="!is24">
            <button @click="toggleAMPM" class="chevron">
              <UpOutlined />
            </button>
            <button class="time-input-box">{{ isAM ? 'AM' : 'PM' }}</button>
            <button @click="toggleAMPM" class="chevron">
              <DownOutlined />
            </button>
          </div>
        </div>
        <button class="close-button" @click="closeTimeSelector">CLOSE</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from "vue";
import moment from 'moment';
import {
  UpOutlined,
  DownOutlined,
  ClockCircleOutlined
} from "@ant-design/icons-vue"

export default defineComponent({
  name: "form-time-input",
  components: {
    UpOutlined,
    DownOutlined,
    ClockCircleOutlined
  },
  props: {
    modelValue: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: "No time selected",
    },
    state: {
      type: Boolean || null,
      default: null
    },
    is24: {
      type: Boolean,
      default: false
    },
    sm: {
      type: Boolean,
      default: false
    },
    autoPosition: {
      type: Boolean,
      default: false

    },
    locale: String
  },
  setup(props, { emit }) {
    const timeValue = ref(props.modelValue);
    const hours = ref(12);
    const minutes = ref(0);
    const isAM = ref(true);
    const isValid = ref(props.state);
    const showTimeSelector = ref(false);
    const formattedHours = ref('12');
    const formattedMinutes = ref('00');
    const position = ref(props.autoPosition);
    const popupTop = ref(false);
    const placeholder = ref(props.placeholder)

    watch(() => props.state, (newValue) => {
      isValid.value = newValue;
      emit('update:state', newValue);
    });

    watch(() => props.modelValue, (newValue) => {
      if (!newValue) {
        clearDateTime();
      }
    });

    watch(() => position.value, (newValue) => {
      if (newValue) {
        const inputElement = document.querySelector('.selected-time');
        if (inputElement) {
          const inputBottom = inputElement.getBoundingClientRect().bottom;
          const windowHeight = window.innerHeight;
          popupTop.value = inputBottom + 300 > windowHeight;
        }
      }
    });

    const formattedTime = computed(() => {
      const suffix = isAM.value ? 'AM' : 'PM';
      const hr = timeValue.value.split(':');
      let formattedHour: number = parseInt(hr[0]);

      if (timeValue.value && !isNaN(parseInt(hr[0]))) {
        if (props.is24) {
          formattedHour = padZero(formattedHour % 24);
          return `${formattedHour}:${formattedMinutes.value}`;

        } else {
          formattedHour = padZero(formattedHour % 12);
          return `${formattedHour}:${formattedMinutes.value}:${suffix}`;
        }
      } else {
        return placeholder.value;
      }
    });

    const updateTime = () => {
      hours.value = parseInt(formattedHours.value, 10);
      minutes.value = parseInt(formattedMinutes.value, 10);
      let formattedHour24;
      if (props.is24) {
        formattedHour24 = padZero(hours.value);
      } else {
        formattedHour24 = padZero(isAM.value ? hours.value % 12 : hours.value % 12 + 12);
      }

      const suffix = isAM.value ? 'AM' : 'PM';
      const formattedSeconds = padZero(0);

      if (props.locale) {
        const formattedLocaleTime = moment(`${formattedHour24}:${formattedMinutes.value}:${formattedSeconds} ${suffix}`, 'HH:mm:ss A').locale(props.locale).format('HH:mm:ss');
        timeValue.value = formattedLocaleTime;
      } else {
        timeValue.value = `${formattedHour24}:${formattedMinutes.value}:${formattedSeconds}`;
      }

      emit('update:modelValue', timeValue.value);
      emit('input', timeValue.value);
    };
    

    const changeHour = (increment: number) => {
      hours.value = (hours.value + increment + (props.is24 ? 24 : 0)) % (props.is24 ? 24 : 12);
      formattedHours.value = padZero(hours.value);
      updateTime();
    };

    const changeMinute = (increment: number) => {
      minutes.value = (minutes.value + increment + 60) % 60;
      formattedMinutes.value = padZero(minutes.value);
      updateTime();
    };

    const toggleAMPM = () => {
      isAM.value = !isAM.value;
      updateTime();
    };
    const openTimeSelector = () => {
      showTimeSelector.value = true;
    }

    const closeTimeSelector = () => {
      showTimeSelector.value = false;
    };

    const padZero = (value: any) => {
      return value.toString().padStart(2, '0');
    };

    const clearDateTime = () => {
      timeValue.value = '';
    };

    return {
      timeValue,
      hours,
      minutes,
      isAM,
      showTimeSelector,
      formattedHours,
      formattedMinutes,
      formattedTime,
      isValid,
      clearDateTime,
      updateTime,
      changeMinute,
      changeHour,
      toggleAMPM,
      openTimeSelector,
      closeTimeSelector,
      position,
      popupTop,
    };
  }
});
</script>

<style scoped>
.selected-time {
  display: block;
  width: 100%;
  height: calc(2.75rem + 2px);
  padding: .625rem .75rem;
  padding-left: 40px;
  font-size: .8rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  border: 1px solid #cad1d7;
  border-radius: .25rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
}

.selected-time:focus {
  outline: none;
}

.green-border {
  border-color: green;
}

.red-border {
  border-color: red;
}

.small {
  height: 31px;
}

.popup-bottom {
  top: -215px !important;
  left: 250px !important;
}

.popup-top {
  top: 2px !important;
}

.time-picker {
  display: inline-block;
  width: 100%;
  position: relative
}

.picker-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.time-selector-input {
  display: block;
  width: 100%;
  max-width: 150px;
  font-weight: 400;
  margin-bottom: 4px;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  box-shadow: none;
  transition: all .2s cubic-bezier(.68, -.55, .265, 1.55);
  height: calc(1.8125rem + 2px);
  line-height: 1.5;
  border-radius: .2rem;
  text-align: center;
  padding: .25rem;
  font-size: 80%;
  outline: none;
}

.time-selector-popup {
  font-weight: 400;
  line-height: 1.5;
  z-index: 1000;
  float: left;
  min-width: 10rem;
  margin: .125rem 0 0;
  font-size: 1rem;
  color: #525f7f;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, .15);
  border-radius: .3rem;
  box-shadow: 0 50px 100px rgba(50, 50, 93, .1), 0 15px 35px rgba(50, 50, 93, .15), 0 5px 15px rgba(0, 0, 0, .1);
  right: auto;
  bottom: auto;
  display: block;
  padding: .5rem;
  position: absolute;
  transform: translate3d(0px, 43px, 0px);
  top: 2px;
  left: 0px;
  will-change: transform;
}

.sm-position {
  top: -10px;
}

.picker-row {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 5px;
}

.picker-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: .25rem;
  box-shadow: none;
  transition: all .2s cubic-bezier(.68, -.55, .265, 1.55);
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  overflow: hidden;
  padding: 0;
  height: auto;
  width: auto;
}

.time-input-box {
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  text-align: center;
  width: 30px;
  border-top: .0625rem solid #e9ecef;
  border-bottom: .0625rem solid #e9ecef;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: center;
  font-size: inherit;
  outline: 0;
  border: 0;
  background-color: transparent;
  margin: 0 .25rem;
  padding: .25rem 0;
}

.clock-icon-container {
  position: absolute;
  top: 45%;
  left: 10px;
  transform: translateY(-50%);
}

.close-button {
  color: #9ba4b7;
  margin-top: 8px;
  display: flex;
  align-self: flex-end;
  font-weight: 600;
  text-align: center;
  background-color: transparent;
  border-style: none;
  padding: .25rem .5rem;
  font-size: .75rem;
  line-height: 1.5;
  border-radius: .25rem;
  cursor: pointer;
}

.close-button:hover {
  background-color: #f4f5f7;
  border-color: #f4f5f7;
}

.time-input-box::-webkit-inner-spin-button,
.time-input-box::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.time-input-box[type=number] {
  -moz-appearance: textfield;
}

.chevron {
  color: #525f7f;
  text-align: center;
  cursor: pointer;
  fill: currentcolor;
  display: inline-block;
  border: none;
  background-color: transparent;
}

.dots-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 14px;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: #525f7f;
  border-radius: 50%;
  margin: 0 -8px;

}
</style>