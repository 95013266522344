import { App } from 'vue';
import '@/assets/vendor/nucleo/css/nucleo.css'
import '@/assets/vendor/font-awesome/css/font-awesome.css'
// import '@/assets/scss/argon.scss'
import globalComponents from './globalComponents'
import globalDirectives from './globalDirectives'
import VueLazyLoad from 'vue3-lazyload';
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';

export default {
    install(app: App) {
        app.use(globalComponents)
        app.use(globalDirectives)
        app.use(VueLazyLoad, {})
        app.use(VCalendar, {})
    }
}
