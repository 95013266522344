import { FetchBase, fetchable } from '@/modules/common/domain'
import EventsService from '@/modules/products/services/EventsService';
import { IFetchable} from '@/modules/common/domain/types/IFetchable';
import { IFutureEvents, IFutureEvent } from '@/modules/products/domain/types';

export class FutureEventsByDate extends FetchBase implements IFetchable<IFutureEvents>, IFutureEvents {
    public events: IFutureEvent[] = [];

    private eventsService = new EventsService();

    @fetchable
    public async fetch(orgID: string, date: string) {
        this.events = await this.eventsService.getFutureEventsByDate(orgID, date);
    }
}
