import store from '@/store';
import { FetchBase, fetchable } from '@/modules/common/domain'
import { IFetchable } from '@/modules/common/domain/types/IFetchable';
import GovernanceWorkflowService from '@/modules/products/services/GovernanceWorkflowService';
import { IGovernanceReviews, IGovernanceWorkflow, IGovernanceWorkflows, WorkflowState } from '@/modules/products/domain/types';

export class GovernanceReviewsList extends FetchBase implements IFetchable<IGovernanceReviews>, IGovernanceReviews {
    public assigned: IGovernanceWorkflow[] = []
    public created: IGovernanceWorkflow[] = []

    private governanceWorkflowService = new GovernanceWorkflowService();

    @fetchable
    public async fetch(orgID: string) {
        let workflows: IGovernanceWorkflow[] = await this.governanceWorkflowService.getAllGovernanceWorkflows(orgID);
        this.assigned = [];
        this.created = [];

        for (let i = 0; i < workflows.length; i++) {
            if (workflows[i].state !== WorkflowState.CLOSED) {
                if (workflows[i].assignedTo === store.state.$auth.user.userId) {
                    this.assigned.push(workflows[i]);
                } else if (workflows[i].userId === store.state.$auth.user.userId) {
                    this.created.push(workflows[i]);
                }
            }
        }
    }
}
