import * as Axios from "axios";
import BaseService from '../../../framework/services/BaseService';
import store from "@/store";

export default class FileService extends BaseService {
    public async uploadFile(organisationId: string, fileData: any, fromFieldEditor = false): Promise<string> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'post',
            url: `${store.state.apiBase}/private/organisations/${organisationId}/file/upload`,
            headers: {  
                Authorization: `Bearer ${store.state.$auth.token}`
            },
            data: fileData
        }
        const res: any = await this.makeAxiosCall(apiConfig);
        if (res.status === 200 && !fromFieldEditor) {
            store.state.toastText = 'Attachment uploaded successfully.';
            store.state.toastVisible = true;
        }
        return res.data.fileId;
    }

    public async updateFile(organisationId: string, fileData: any, fileId: string, fromFieldEditor = false): Promise<string> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'post',
            url: `${store.state.apiBase}/private/organisations/${organisationId}/file/${fileId}`,
            headers: {  
                Authorization: `Bearer ${store.state.$auth.token}`
            },
            data: fileData
        }
        const res: any = await this.makeAxiosCall(apiConfig);
        if (res.status === 200 && !fromFieldEditor) {
            store.state.toastText = 'Attachment updated successfully.';
            store.state.toastVisible = true;
        }
        return res.data;
    }

    public async getFile(organisationId: string, fileId: string): Promise<any> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/organisations/${organisationId}/file/${fileId}`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res as any;
    }
}
