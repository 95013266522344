import * as Axios from "axios";
import BaseService from '../../../framework/services/BaseService';
import store from "@/store";
import { IFutureEvent } from '@/modules/products/domain/types';

export default class EventsService extends BaseService {
    public async getFutureEvents(orgId: string): Promise<IFutureEvent[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: "get",
            url: `${store.state.apiBase}/private/organisations/${orgId}/events/future`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        };

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig);
        return res.data as IFutureEvent[];
    }

    public async getFutureEventsByDate(orgId: string, date: string): Promise<IFutureEvent[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: "get",
            url: `${store.state.apiBase}/private/organisations/${orgId}/events?date=${date}`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        };

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig);
        return res.data as IFutureEvent[];
    }
}
