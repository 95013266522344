import { FetchBase, fetchable } from '@/modules/common/domain'
import TmdWorkflowService from '@/modules/products/services/TmdWorkflowService';
import { IWorkflowValidate } from '@/modules/products/domain/types';
import { IFetchable } from '@/modules/common/domain/types/IFetchable';

export class TmdWorkflowValidate extends FetchBase implements IFetchable<IWorkflowValidate> {
    private tmdWorkflowService = new TmdWorkflowService();
    public status = 0;

        @fetchable
        public async fetch(organisationId: string, tmdId: string | undefined, isGroupWorkflow: boolean, tmdIds: string[] | [], comment: string, workflowType: string, futureDate: string | undefined) {
            let validation = await this.tmdWorkflowService.validateTmdWorkflow(organisationId, tmdId, isGroupWorkflow, tmdIds, comment, workflowType, futureDate);
            this.status = validation.status;
        }
}
