import * as Generated from '@/modules/products/domain/generated'
import { FetchBase, fetchable } from '@/modules/common/domain'
import FeeHistoryService from '@/modules/products/services/FeeHistoryService';
import { IFetchable } from '@/modules/common/domain/types/IFetchable';
import { IFeeEvent } from '@/modules/products/domain/types';

export class FeeEvent extends FetchBase implements IFetchable<IFeeEvent>, IFeeEvent {
    public eventId = '';
    public feeId = '';
    public organisationId = '';
    public createdAt = '';
    public userId = '';
    public isActive = false;
    public data?: Generated.Fees = undefined;

    private feeHistoryService = new FeeHistoryService();

    @fetchable
    public async fetch(orgID: string, feeId: string, eventId: string) {
        let result: IFeeEvent = await this.feeHistoryService.getFeeEvent(orgID, feeId, eventId);

        this.eventId = result.eventId;
        this.feeId = result.feeId;
        this.organisationId = result.organisationId;
        this.createdAt = result.createdAt;
        this.userId = result.userId;
        this.isActive = result.isActive;
        this.data = result.data;
    }
}