export enum PlatformStatusType {
    OK = "OK",
    PARTIAL_FAILURE = "PARTIAL_FAILURE",
    UNAVAILABLE = "UNAVAILABLE",
    SCHEDULED_OUTAGE = "SCHEDULED_OUTAGE"
}
export interface IPlatformStatus {
    status: PlatformStatusType | undefined;
    explanation?: string;
    detectionTime?: string;
    expectedResolutionTime?: string;
    updateTime: string;
}
