import { App as Application, createApp} from 'vue';
import store from '@/store';
import { ICredentials, IAuthResult, AuthStatus } from "@/modules/common/domain/types"

import AuthenticationService from '@/framework/services/AuthenticationService';

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = (appState: any) => {
    window.history.replaceState({}, document.title, window.location.pathname)
}

let instance: any

const authenticationService = new AuthenticationService();

/** Returns the current instance */
export const getInstance = (): any => { return instance }

/** Creates an instance of the Auth library. If one has already been created, it returns that instance */
export const useAuth = (app: Application,{
    onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
}: any) => {
    if (instance) return instance

    // The 'instance' is simply a Vue object
    instance = createApp({
        data() {
            return {
                loading: true,
                isAuthenticated: false,
                token: null,
                supportToken: null,
                user: {},
                error: null,
                challenge: {}
            }
        },
        methods: {
            async handleDeepLink(): Promise<any> {
                this.loading = true
                try {
                    this.token = (await authenticationService.silentLogin()).token;
                    this.user = await authenticationService.getUser();
                    this.isAuthenticated = true;
                } catch (e) {
                    this.error = e
                } finally {
                    this.loading = false
                }
            },
            async startLogin(credentials: ICredentials): Promise<AuthStatus> {
                const result = await authenticationService.login(credentials);
                if (result.token) this.token = result.token;
                if (result.challenge) this.challenge = result.challenge;

                store.commit('loginStarted');
                return result.status;
            },
            async completeLogin(targetURL?: string, token?: string) {
                if (token) this.token = token;
                this.user = await authenticationService.getUser();
                this.isAuthenticated = true;

                store.commit('loginComplete');
                onRedirectCallback({ targetUrl: targetURL || '/landing'});
            },            /** Logs the user out and removes their session on the authorization server */
            async silentLogin(token: string) {
                const result = await authenticationService.silentLogin(token);
                if (result.token) this.token = result.token;
                return result.status;
            },
            async logout(o: any) {
                await authenticationService.logout();
                this.token = "";
                this.user = {};
                this.challenge = {};
                this.isAuthenticated = false;

                store.commit('logout');

                onRedirectCallback({ targetUrl: '/' });
            },
            async generateSupportSSOToken() {
                this.supportToken = await authenticationService.generateSupportToken();                
            }
        },
        /** Use this lifecycle method to instantiate the SDK client */
        async created() {
            try {
                this.isAuthenticated = false;
                this.user = {};
                this.loading = false;

                await this.handleDeepLink();
            } catch (e) {
                this.error = e
            } finally {
                store.commit('loginComplete');
            }
        }
    }).mount('#auth-component');

    return instance;
}

// Create a simple Vue plugin to expose the wrapper object throughout the application
export function AuthPlugin(app: Application, options?: any): void {
    try {
        store.state.$auth = useAuth(app,options);
    } catch (e) {
        console.log(`Error initialzing security handler: ${e}`);
    }
}
