export default {
    beforeMount(el: any, binding: any) {
        el.tooltipText = binding.value;
        el.tooltipPosition = getPosition(binding.modifiers);
        el.handleMouseenter = (event: any) => showTooltip(event, el);
        el.handleMouseleave = (event: any) => hideTooltip(el,event);
        el.handleClick = (event: any) => hideTooltip(el, event)

        el.addEventListener('mouseenter', el.handleMouseenter);
        el.addEventListener('mouseleave', el.handleMouseleave);
        el.addEventListener('click', el.handleClick)
    },

    updated(el: any, binding: any) {
        updateTooltip(el, binding);
    },
    
    unmounted(el: any) {
        el.removeEventListener('mouseenter', el.handleMouseenter);
        el.removeEventListener('mouseleave', el.handleMouseleave);
        delete el.handleMouseenter;
        delete el.handleMouseleave;
        delete el.tooltipText;
        delete el.tooltipPosition;
    },
};

function updateTooltip(el: any, binding: any) {
    el.tooltipText = binding.value;
}

function showTooltip(event: any, el:any) {
    const tooltip = document.createElement('div');
    tooltip.className = `custom-tooltip ${el.tooltipPosition}`

    const triangle = document.createElement('div');
    triangle.className = 'tooltip-triangle';
    tooltip.appendChild(triangle);

    // Set tooltip text
    const content = document.createElement('div');
    content.className = 'tooltip-content';
    content.innerHTML = el.tooltipText;
    tooltip.appendChild(content);

    document.body.appendChild(tooltip);


    const rect = el.getBoundingClientRect();
    const tooltipRect = tooltip.getBoundingClientRect();

    let top, left;

    switch (el.tooltipPosition) {
        case 'bottom':
        top = rect.bottom + window.scrollY + 10;
        left = rect.left + window.scrollX + rect.width / 2 - tooltipRect.width / 2;
        break;
        case 'left':
        top = rect.top + window.scrollY + rect.height / 2 - tooltipRect.height / 2;
        left = rect.left + window.scrollX - tooltipRect.width - 10;
        break;
        case 'right':
        top = rect.top + window.scrollY + rect.height / 2 - tooltipRect.height / 2;
        left = rect.right + window.scrollX + 10;
        break;
        default:
        // 'top' or invalid position, default to top
        top = rect.top + window.scrollY - tooltipRect.height - 10;
        left = rect.left + window.scrollX + rect.width / 2 - tooltipRect.width / 2;
    }

    tooltip.style.position = 'absolute';
    tooltip.style.top = `${top}px`;
    tooltip.style.left = `${left}px`;
    
    el.tooltipElement = tooltip;
}

function hideTooltip(el: any, event: any) {
  if (el.tooltipElement) {
    document.body.removeChild(el.tooltipElement);
    delete el.tooltipElement;
  }
}

function getPosition(modifiers: any) {
  if (modifiers.top) {
      return 'top';
  } else if (modifiers.bottom) {
      return 'bottom';
  } else if (modifiers.left) {
      return 'left';
  } else if (modifiers.right) {
      return 'right';
  } 
      
  return 'top';
}