import { FetchBase, fetchable } from '@/modules/common/domain'
import { TargetMarketDetails } from './TargetMarketDetails'
import RecentsService from '@/modules/products/services/RecentsService';
import { ITargetMarket, ITargetMarketRecents } from '@/modules/products/domain/types';
import { IFetchable } from '@/modules/common/domain/types/IFetchable';

export class TargetMarketRecents extends FetchBase implements IFetchable<ITargetMarketRecents>, ITargetMarketRecents {
    public tmds: ITargetMarket[] = []

    private recentsService = new RecentsService();

    @fetchable
    public async fetch(orgID: string) {
        const tmds: ITargetMarket[] = await this.recentsService.getRecentTMDs(orgID);

        this.tmds = [];

        for (let rawTmd of tmds) {
            let newTmd: ITargetMarket = new TargetMarketDetails()
            newTmd.tmdId = rawTmd.tmdId
            newTmd.marketCategory = rawTmd.marketCategory
            newTmd.name = rawTmd.name
            newTmd.isWip = rawTmd.isWip
            newTmd.isPublished = rawTmd.isPublished
            newTmd.workflowId = rawTmd.workflowId

            this.tmds.push(newTmd)
        }
    }
}
