import { FetchBase, fetchable } from '@/modules/common/domain'
import FeeHistoryService from '@/modules/products/services/FeeHistoryService';
import { IFetchable } from '@/modules/common/domain/types/IFetchable';
import { IFeeHistory, IFeeEvent } from '@/modules/products/domain/types';


export class FeeChangeHistory extends FetchBase implements IFetchable<IFeeHistory>, IFeeHistory {
    public feeEvents: IFeeEvent[] = []

    private feeHistoryService = new FeeHistoryService();
    
    @fetchable
    public async fetch(orgID: string, feeId: string) {
        this.feeEvents = await this.feeHistoryService.getFeeEvents(orgID, feeId);
    }
    
}