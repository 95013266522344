import * as Generated from '@/modules/products/domain/generated'
import { FetchBase, fetchable } from '@/modules/common/domain'
import HistoryService from '@/modules/products/services/HistoryService';
import { IProductEvent, IAttachment } from '@/modules/products/domain/types';
import { File } from '@/modules/products/domain/File';
import { IFetchable } from '@/modules/common/domain/types/IFetchable';

export class ProductEvent extends FetchBase implements IFetchable<IProductEvent>, IProductEvent {
    public eventId = '';
    public productId = '';
    public organisationId = '';
    public createdAt = '';
    public userId = '';
    public isPublished = false;
    public product?: Generated.Product = undefined;

    private historyService = new HistoryService();
    private file = new File();

    @fetchable
    public async fetch(orgID: string, productId: string, eventId: string) {
        let attachments;
        let result: IProductEvent = await this.historyService.getProductEvent(orgID, productId, eventId);

        this.eventId = result.eventId;
        this.productId = result.productId;
        this.organisationId = result.organisationId;
        this.createdAt = result.createdAt;
        this.userId = result.userId;
        this.isPublished = result.isPublished;
        this.product = result.product;
    }
}
