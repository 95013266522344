<template>
  <div v-if="slots.label || label" class="row mb-1">
    <div class="col">
      <slot name="label">
          <span :class="['data-h', { 'data-mandatory': required }]">{{
              label
          }}</span>
      </slot>
      <warning-icon v-if="isWarning" :toolTipTitle="warningTooltip" />
    </div>
</div>
<div class="row">
  <div class="col">
    <select
      v-model="selectedValue"
      :class="['form-select', 'data-v', `form-select-${size}`,
          { 'error-border': highlightError }]"
      @change="handleChange"
      :disabled="disabled"
  >
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
        :disabled="option.disabled"
      >
        {{ option.text }}
      </option>
    </select>
  </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, watch, ref, useSlots, computed } from "vue";
import WarningIcon from "@/modules/common/components/argon/WarningIcon.vue";

export default defineComponent({
  name: 'form-select',
  props: {
    size: {
      type: String,
      default: "sm",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    options: Array,
    label: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    showWarning: {
      type: Boolean,
      default: false,
    },
    warningToolTipTitle: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    highlightError: {
      type: Boolean,
      default: false
    }
  },
  components: {
    WarningIcon,
  },
  emits:[
    "change",
    "update:modelValue"
  ],
  setup(props, { emit }) {
    const slots = useSlots();

    const selectedValue = ref(props.modelValue);
    
    watch(() => props.modelValue,
    () => {
      selectedValue.value = props.modelValue
    },
    {
      deep: true,
      immediate: true
    })

    watch(selectedValue, (newValue: any) => {
      emit("update:modelValue", newValue);
    });

    const handleChange = () => {
      emit("change", selectedValue.value);
    };

    const isWarning = computed(() => {
      if (props.required && !selectedValue.value) {
        return true;
      }
      if (props.showWarning) return true;
      return false;
    })

    const warningTooltip = computed(() => {
      if (props.required && !selectedValue.value) {
        return 'mandatoryField'
      }
      else {
        return props.warningToolTipTitle
      }
    })

    return {
      selectedValue,
      slots,
      handleChange,
      isWarning,
      warningTooltip
    };
  },
});
</script>

<style scoped lang="scss">
.form-select-sm {
  height: calc(1.8125rem + 2px);
}

.error-border {
  border: 1px solid red !important;
}

</style>
