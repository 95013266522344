import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card card-box", [
       {'card-lift--hover': _ctx.hover},
       {'shadow': _ctx.shadow},
       {[`bg-gradient-${_ctx.gradient}`]: _ctx.gradient},
       {[`bg-${_ctx.type}`]: _ctx.type}
     ]])
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 2))
}