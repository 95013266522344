<template>
	<div>
		<div class="text-left">
			<div :href="`#${id}`":class="[headerClass, { 'collapsed': !visible }, 'invalid-style']"
				data-bs-toggle="collapse" :aria-expanded="visible" class="border-bottom navigation local-collapse dropdown">

				<span v-if="heading" class="h5 font-weight-400 ms-2">
					{{ heading }}
				</span>
				<slot v-else name="headingSlot">
				</slot>
				<i class="fa fa-fw fa-chevron-down opened chevron-spacing" ></i>
				<i class="fa fa-fw fa-chevron-left closed chevron-spacing" ></i>
				
			</div>
		</div>
		<div :id="id" class="collapsible collapse" :class="{ 'show': visible }">
			<slot></slot>
		</div>
	</div>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue"

export default defineComponent({
  name: 'base-collapse-invalid',
  props: {
    id: {
      type: String,
      required: true
    },
    heading: {
      type: String
    },
    headerClass: {
      type: String
    },
    visible: {
      type: Boolean,
      default: false
    },
    isInvalidDropdown: {
			type: Boolean,
			default: false
		}
  },
  setup(props) {
    const isOpen = ref(props.visible)

    function toggleCollapse() {
      isOpen.value = !isOpen.value;
    }

    return {
      isOpen,
      toggleCollapse
    }
  }
})
</script>
<style scoped lang="scss">
@import "~bootstrap/scss/functions";
@import "@/assets/scss/custom/_functions";
@import "@/assets/scss/custom/_variables";
@import "~bootstrap/scss/variables";

.collapsible {
	width: 100%;
	margin: 0;
	padding: 0;
}

.collapse-button {
	margin: 0;
	padding: 0;
	background-color: transparent;
	border: none;
	cursor: pointer;
}

.collapse-button:focus {
	outline: none
}

.collapsed .opened,
.not-collapsed .closed {
	display: none;
}

.invalid-style {
	border-radius: 8px;
	border: 1px solid #f8d7da !important;
}

.dropdown {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
</style>
