import { FetchBase, fetchable } from '@/modules/common/domain'
import MarketHistoryService from '@/modules/products/services/MarketHistoryService';
import { IMarketProductChangeHistory, IMarketHistory } from '@/modules/products/domain/types';
import { IFetchable } from '@/modules/common/domain/types/IFetchable';

export class MarketHistory extends FetchBase implements IFetchable<IMarketHistory>, IMarketHistory {
    public audits: IMarketProductChangeHistory[] = []

    private historyService = new MarketHistoryService();

    @fetchable
    public async fetch(orgID: string, productId: string) {
        this.audits = await this.historyService.getMarketProductHistory(orgID, productId);
    }
}
