import { ICredentials, IAuthResult } from "@/modules/common/domain/types";
import * as Axios from 'axios';
import store from '@/store';
import { User } from "@/modules/common/domain/User";
import { CookieService } from "./CookieService";

export default class AuthenticationService {
    private cookieService = new CookieService();

    public async login(credentials: ICredentials): Promise<IAuthResult> {
        try {
            const apiConfig: Axios.AxiosRequestConfig = {
                method: 'post',
                url: `${store.state.apiBase}/public/token`,
                data: {
                    ...credentials
                }
            }
            const res: Axios.AxiosResponse = await Axios.default(apiConfig);

            this.cookieService.setCookie("product-cloud", res.data.token, 1);

            return res.data as IAuthResult;
        } catch(e: any) {
            if (e.response && e.response.data && e.response.data.message) throw Error(e.response.data.message);
            if (e.message === 'Network Error') throw Error('Authentication Server Unavailable');
            if (e.response.status === 403) throw Error('Incorrect username or password');
            if (e.response.status === 500) throw Error('Server Error');
            else throw Error(`Unhandled Error: ${e.message}`);
        }
    }

    public async loginSSO(credentials: ICredentials): Promise<string> {
        try {
            const apiConfig: Axios.AxiosRequestConfig = {
                method: 'post',
                url: `${store.state.apiBase}/public/sso/init`,
                data: {
                    username: credentials.username,
                    redirectURI: `${window.location.origin}/login`,
                    targetUrl: credentials.targetUrl                }
            }
            const res: Axios.AxiosResponse = await Axios.default(apiConfig);

            return res.data;
        } catch(e: any) {
            throw Error(`Unhandled Error: ${e.message}`);
        }
    }

    public async generateSupportToken(): Promise<string> {
        try {
            const apiConfig: Axios.AxiosRequestConfig = {
                method: 'get',
                url: `${store.state.apiBase}/public/sso/support`,
                headers: { Authorization: `Bearer ${store.state.$auth.token}` }
            }

            const res: Axios.AxiosResponse = await Axios.default(apiConfig);
            return res.data.data;
        } catch(e: any) {
            throw Error(`Unhandled Error: ${e.message}`);
        }
    }

    public async silentLogin(authToken?: string): Promise<IAuthResult> {
        const token = authToken || this.cookieService.getCookie("product-cloud");

        if (!token)  throw Error('No valid token available');

        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'post',
            url: `${store.state.apiBase}/public/token/refresh`,
            data: {
                token
            }
        }
        const res: Axios.AxiosResponse = await Axios.default(apiConfig);

        this.cookieService.setCookie("product-cloud", res.data.token, 1);

        return res.data;
    }

    public async logout(): Promise<void> {
        this.cookieService.deleteCookie('product-cloud');
    }

    public async sendResetPasswordLink(username: string): Promise<string> {
        try {
            const apiConfig: Axios.AxiosRequestConfig = {
                method: 'post',
                url: `${store.state.apiBase}/public/password/sendResetEmail`,
                data: {
                    email: username
                }
            };
            const res: Axios.AxiosResponse = await Axios.default(apiConfig);

            return res.data.response;
        } catch(e: any) {
            if (e.message === 'Network Error') throw Error('Authentication Server Unavailable');
            else throw Error(`Unhandled Error: ${e.message}`);
        }
    }

    public async resetPassword(resetId: string , newPassword: string): Promise<string> {
        try {
            const apiConfig: Axios.AxiosRequestConfig = {
                method: 'post',
                url: `${store.state.apiBase}/public/password/reset`,
                data: {
                    resetId: resetId,
                    newPassword: newPassword
                }
            };
            const res: Axios.AxiosResponse = await Axios.default(apiConfig);
            return res.data.response.message;
        } catch(e: any) {
            if (e.response && e.response.data && e.response.data.message) throw Error(e.response.data.message);
            if (e.message === 'Network Error') throw Error('Authentication Server Unavailable');
            if (e.response.status === 500) throw Error('Server Error');
            else throw Error(`Unhandled Error: ${e.message}`);
        }
    }

    public async getUser(): Promise<User> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/profile`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await Axios.default(apiConfig)
        return res.data as User;
    }
}
