import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "name", "value", "checked", "disabled"]
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "form-check mb-0",
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clicked && _ctx.clicked(...args)))
  }, [
    _createElementVNode("input", {
      ref: "radioInput",
      type: "radio",
      class: "form-check-input",
      id: _ctx.id,
      name: _ctx.name,
      value: _ctx.value,
      checked: _ctx.modelValue === _ctx.value,
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleChange(_ctx.value))),
      disabled: _ctx.disabled
    }, null, 40, _hoisted_1),
    _createElementVNode("label", {
      class: "form-check-label ms-4",
      for: _ctx.id
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 8, _hoisted_2)
  ]))
}