import * as Axios from 'axios';
import BaseService from '../../../framework/services/BaseService';
import store from '@/store';
import { IMarketHolder, IMarketPortfolio, IProduct, IMarketChangeSummary, IMarketChange, IMarketFavourites, IMarketProduct } from '@/modules/products/domain/types';

export default class IndustryService extends BaseService {
    public async getMarketHolders(): Promise<IMarketHolder[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/industry/holders`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IMarketHolder[];
    }

    public async getMarketPortfolio(holderId: string): Promise<IMarketPortfolio> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/industry/holders/${holderId}/products`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IMarketPortfolio;
    }

    public async getMarketProduct(holderId: string, productId: string): Promise<IProduct> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/industry/holders/${holderId}/products/${productId}`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IProduct;
    }

    public async getMarketChangeSummary(startDate? : string, endDate?: string): Promise<IMarketChangeSummary> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/industry/changes`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            params: { startDate, endDate }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IMarketChangeSummary;
    }

    public async getMarketChanges(date: string): Promise<IMarketChange[]> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/industry/changes/${date}`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IMarketChange[];
    }

    public async addMarketFavourite(favourites: IMarketFavourites): Promise<IMarketFavourites> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: "post",
            url: `${store.state.apiBase}/private/industry/favourites`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            data: {
                favourites
            }
        };
        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig);
        if (res.status === 200) {
            store.state.toastVisible = true;
        }
        return res.data;
    }

    public async getMarketFavourites(): Promise<IMarketFavourites> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/industry/favourites`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IMarketFavourites;
    }
}
