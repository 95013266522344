import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bb95cff2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "data-v border-left border-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("ul", {
      class: _normalizeClass(["spin-button form-control", {'disabled': _ctx.disabled}]),
      id: _ctx.id
    }, [
      _createElementVNode("li", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.decrement && _ctx.decrement(...args))),
        class: _normalizeClass([{ 'disable': _ctx.inputValue <= _ctx.min }, 'data-v'])
      }, " - ", 2),
      _createElementVNode("li", _hoisted_2, _toDisplayString(_ctx.inputValue), 1),
      _createElementVNode("li", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.increment && _ctx.increment(...args))),
        class: _normalizeClass([{ 'disable': _ctx.inputValue >= _ctx.max },'data-v'])
      }, " + ", 2)
    ], 10, _hoisted_1)
  ]))
}